import { makeStyles } from "../../styles";

export default makeStyles()((theme) => ({
  inputBox: {
    paddingBottom: theme.spacing(2),
  },
  formLinks: {
    fontSize: 14,
    "& a": {
      display: "block",
    },
  },
  formFooter: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(20),
  },
  showPasswordBtn: {
    "& svg": {
      color: theme.palette.grey[500],
      width: 16,
    },
  },
}));
