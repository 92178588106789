import { useContext } from "react";
import { Navigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import { userToken } from "../utils/Cookies";
import { AccountContext } from "../contexts/CurrentUser";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  guest?: boolean;
}

const ProtectedRoute: React.FC<Props> = ({ children, guest }) => {
  const currentUser = useContext(AccountContext);

  const shouldRedirect = () => {
    if (guest) {
      return !isEmpty(currentUser.account);
    } else {
      return isEmpty(userToken.read()) || isEmpty(currentUser);
    }
  };

  const redirectDestination = () => {
    return guest ? "/" : "/user/login";
  };

  if (shouldRedirect()) {
    return <Navigate to={redirectDestination()} />;
  } else {
    return <>{children}</>;
  }
};

export default ProtectedRoute;
