import { useEffect, useState, useContext } from "react";
import isEmpty from "lodash/isEmpty";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import axios from "axios";
import { apiUrl } from "../../utils/Axios";

import MainLayout from "../../themes/layouts/Main";
import { AccountContext } from "../../contexts/CurrentUser";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { userToken } from "../../utils/Cookies";

const UserActivateRoute: React.FC = () => {
  const params = useParams<{ code: string }>();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  const { accountRefetch } = useContext(AccountContext);

  const activate = (): void => {
    try {
      axios
        .post(apiUrl() + "activate-account", { code: params.code })
        .then((response) => {
          const userActivated = response.data.userActivated;
          if (isEmpty(userActivated.messages) && userActivated.result) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            userToken.write(userActivated.result.token);
            notifySimple(["Account confirmed!"], "success");
            void accountRefetch();
            setLoaded(true);
            navigate("/");
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            notifySimple([userActivated.messages], "error");
            setLoaded(false);
          }
        })
        .catch((error) => {
          setLoaded(false);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          notify(error.response.data.userActivated.messages, "error");
        });
    } catch (error) {
      setLoaded(false);
    }
  };

  useEffect(() => {
    if (!loaded) void activate();
  }, [params, loaded, activate]);

  return (
    <MainLayout>
      <Box flexGrow={1} alignItems="center" justifyContent="center" display="flex"></Box>
    </MainLayout>
  );
};

export default UserActivateRoute;
