import { createRef } from "react";
import { SnackbarProvider, SnackbarKey } from "notistack";
import { CacheProvider } from "@emotion/react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";

import { AccountProvider } from "./contexts/CurrentUser";
import { SnackbarUtilsConfigurator } from "./themes/components/Notifications/Snackbar";
import { muiCache } from "./styles";
import MuiTheme from "./themes/Base";

import Router from "./Router";

const App: React.FC = () => {
  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey | undefined) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <CacheProvider value={muiCache}>
      <MuiTheme>
        <CssBaseline />
        <SnackbarProvider
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          autoHideDuration={4000}
          maxSnack={6}
          ref={notistackRef}
          action={(key) => (
            <Button onClick={onClickDismiss(key)} style={{ color: "#fff" }}>
              Dismiss
            </Button>
          )}
        >
          <SnackbarUtilsConfigurator />
          <AccountProvider>
            <Router />
          </AccountProvider>
        </SnackbarProvider>
      </MuiTheme>
    </CacheProvider>
  );
};

export default App;
