import { useContext } from "react";
import { Formik, Form, FastField } from "formik";
import { TextField } from "formik-material-ui";
import TextFieldMUI from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Loader from "../../themes/components/Loader";

import ProfileLayout from "../../themes/layouts/Profile";
import useStyles from "./Styles";
import useButtonStyles from "../../styles/Buttons";
import { AccountContext } from "../../contexts/CurrentUser";

type Props = {
  onSubmit: (values: UserUpdateVars, formikBag: unknown) => void | Promise<unknown>;
};

const Profile: React.FC<Props> = ({ onSubmit }) => {
  const { account } = useContext(AccountContext);

  const { classes } = useStyles();
  const { classes: buttonClasses } = useButtonStyles();

  if (account === null) {
    return <Loader />;
  }

  return (
    <ProfileLayout>
      <div>
        <Typography variant="h3">Profile</Typography>
        <Formik<UserUpdateVars>
          initialValues={{
            input: {
              first_name: account ? account.first_name : "",
              last_name: account ? account.last_name : "",
            },
          }}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <div className={classes.mainCol}>
                <div className={classes.inputBox}>
                  <TextFieldMUI
                    label="Account Email"
                    fullWidth
                    variant="standard"
                    type="text"
                    disabled
                    value={account ? account.email : ""}
                  />
                </div>
                <div className={classes.inputBox}>
                  <FastField
                    component={TextField}
                    name="input.first_name"
                    label="First Name"
                    fullWidth
                    variant="standard"
                  />
                </div>
                <div className={classes.inputBox}>
                  <FastField
                    component={TextField}
                    name="input.last_name"
                    label="Last Name"
                    fullWidth
                    variant="standard"
                    type="text"
                  />
                </div>
              </div>

              <Button type="submit" className={buttonClasses.blue}>
                Save Changes
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </ProfileLayout>
  );
};

export default Profile;
