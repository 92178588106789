import MainLayout from "../../themes/layouts/Main";

const PrivacyPolicy: React.FC = () => {
  return (
    <MainLayout>
      <div>
        <div>Privacy Policy</div>
        <div>
          <p>Last modified: XXXX XX, 2021</p>
          <div>Introduction</div>
        </div>
      </div>
    </MainLayout>
  );
};

export default PrivacyPolicy;
