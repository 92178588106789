import { forwardRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import useButtonStyles from "../../styles/Buttons";

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: JSX.Element;
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

type Props = {
  title?: string;
  description?: string;
  okButton: string;
  cancelButton: string;
  okAction: () => void;
  closeAction: () => void;
  show: boolean;
};

const ConfirmationDialog: React.FC<Props> = ({
  title,
  description,
  okButton,
  cancelButton,
  okAction,
  closeAction,
  show,
}: Props) => {
  const [open, setOpen] = useState(false);

  const { classes: buttonClasses, cx } = useButtonStyles();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleCancel = () => {
    handleClose();
  };

  const handleOk = () => {
    okAction();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    closeAction();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {title && <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>}
        {description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">{description}</DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleCancel} className={cx(buttonClasses.small, buttonClasses.red)}>
            {cancelButton}
          </Button>
          <Button onClick={handleOk} className={cx(buttonClasses.small, buttonClasses.blue)}>
            {okButton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
