import { useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import * as XLSX from "xlsx";
import isEmpty from "lodash/isEmpty";

import { useSelectAll } from "../../hooks/useSelectAll";
import { getLocalTime, dateFormat } from "../../utils/Time";
import { notify, notifySimple } from "../../themes/components/Notifications";
import useModalStyles from "../../styles/Modals";
import useButtonStyles from "../../styles/Buttons";
import useSharedStyles from "../../styles/Shared";

import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { updateResponses } from "../../api/Project";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  responses: ProjectResponse[];
  baseName: string;
  refetch: () => void;
};

const ResponsesModal: React.FC<Props> = ({ open, setOpen, responses, baseName, refetch }) => {
  const { classes: modalClasses } = useModalStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();
  const { classes: sharedClasses } = useSharedStyles();

  const { id } = useParams<{ id: Project["id"] }>();

  const [statusFilter, setStatusFilter] = useState("");
  const [searchPhrase, setSearchPhrase] = useState("");
  const [newStatus, setNewStatus] = useState("test");

  const searchedResponses = responses.filter((resp) => {
    if (isEmpty(searchPhrase)) {
      return true;
    } else {
      return (
        resp.id.indexOf(searchPhrase) !== -1 ||
        Object.entries(resp.params).some((el) => el[1].indexOf(searchPhrase) !== -1)
      );
    }
  });

  const filteredResponses = isEmpty(statusFilter)
    ? searchedResponses
    : searchedResponses.filter((resp) => resp.status === statusFilter);

  const {
    isChecked,
    selectedCount,
    selectedList,
    clearSelectedList,
    handleCheckboxClick,
    handleSelectAllClick,
  } = useSelectAll(filteredResponses);

  const handleUpdateResponses = async () => {
    try {
      const response = await updateResponses({ ids: selectedList, status: newStatus });
      const { messages } = response.projectResponseStatusBatchUpdate;

      if (!isEmpty(messages)) {
        notify(messages);
      } else {
        notifySimple(["Responses updated!"], "success");
        clearSelectedList();
        refetch();
      }
    } catch (err) {
      notifySimple(["Error updating project"], "error");
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={() => setOpen(false)} keepMounted={false}>
      <IconButton className={modalClasses.close} onClick={() => setOpen(false)}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" mr={3}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            Responses
            <TextField
              sx={{ marginLeft: 4, width: 250 }}
              variant="standard"
              label="Search by ID or URL Params"
              value={searchPhrase}
              onChange={(e) => setSearchPhrase(e.target.value)}
            />
            <FormControl sx={{ width: 200, marginLeft: 2 }}>
              <InputLabel>Filter by Status</InputLabel>
              <Select
                sx={{ marginLeft: 2 }}
                variant="standard"
                label="Filter by Status"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="entered">Entered</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
                <MenuItem value="quota_full">Quota Full</MenuItem>
                <MenuItem value="screened_out">Screened Out</MenuItem>
                <MenuItem value="terminated">Terminated</MenuItem>
                <MenuItem value="test">Test</MenuItem>
              </Select>
            </FormControl>
            {selectedCount > 0 && (
              <Box sx={{ display: "flex", alignItems: "flex-end", marginLeft: 2 }}>
                <FormControl sx={{ width: 250 }}>
                  <InputLabel>
                    New status for {selectedCount} response{selectedCount > 1 && "s"}
                  </InputLabel>
                  <Select
                    sx={{ marginLeft: 2 }}
                    variant="standard"
                    value={newStatus}
                    onChange={(e) => setNewStatus(e.target.value)}
                  >
                    <MenuItem value="entered">Entered</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                    <MenuItem value="quota_full">Quota Full</MenuItem>
                    <MenuItem value="screened_out">Screened Out</MenuItem>
                    <MenuItem value="terminated">Terminated</MenuItem>
                    <MenuItem value="test">Test</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  sx={{ marginLeft: 1 }}
                  className={cx(buttonClasses.green, buttonClasses.small)}
                  onClick={() => {
                    void handleUpdateResponses();
                  }}
                >
                  Update
                </Button>
              </Box>
            )}
          </Box>

          <Button
            style={{ marginLeft: 10 }}
            className={cx(buttonClasses.blue, buttonClasses.iconButton)}
            onClick={() => {
              const table = document.querySelector("#ResponsesTable");
              if (table) {
                const workbook = XLSX.utils.book_new();
                const opts: XLSX.Table2SheetOpts = { raw: false };
                const sheet = XLSX.utils.table_to_sheet(table, opts);
                XLSX.utils.book_append_sheet(workbook, sheet, `Responses`);

                XLSX.writeFile(workbook, `${baseName}_responses.xlsx`);
              }
            }}
          >
            <FileDownloadIcon /> XLSX
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent className={modalClasses.content}>
        <Table id="ResponsesTable" className={sharedClasses.responsesContentTable}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 50 }}>
                <Checkbox
                  indeterminate={selectedCount > 0 && selectedCount < filteredResponses.length}
                  checked={selectedCount > 0 && selectedCount === filteredResponses.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell>#</TableCell>
              <TableCell>Internal ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Entered</TableCell>
              <TableCell>Finished</TableCell>
              <TableCell>IP</TableCell>
              <TableCell>Device</TableCell>
              <TableCell>Browser</TableCell>
              <TableCell>URL Params</TableCell>
              <TableCell>Device Forensiq</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredResponses.map((response, i) => (
              <TableRow key={response.id}>
                <TableCell>
                  <Checkbox
                    checked={isChecked(response.id)}
                    onClick={() => handleCheckboxClick(response.id)}
                  />
                </TableCell>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{response.id}</TableCell>
                <TableCell>{response.status.toLowerCase().replace("_", " ")}</TableCell>
                <TableCell>{getLocalTime(response.inserted_at).format(dateFormat)}</TableCell>
                <TableCell>
                  {response.finished_at && getLocalTime(response.finished_at).format(dateFormat)}
                </TableCell>
                <TableCell>{response.ip_address}</TableCell>
                <TableCell>{response.device.toLowerCase()}</TableCell>
                <TableCell>{response.browser}</TableCell>
                <TableCell>
                  {Object.entries(response.params).map((el, j) => (
                    <div key={j}>{`${el[0]}: ${el[1]}\n`}</div>
                  ))}
                </TableCell>
                <TableCell>
                  {response.device_forensiq ? (
                    <a href={`/respondent/${response.id}/device-forensiq`} target="_blank">
                      View Raw Data
                    </a>
                  ) : 'No data found'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default ResponsesModal;
