import { getRequest, patchRequest, postRequest, deleteRequest } from "../utils/Axios";

/**
 * Fetches all of the projects
 * @returns Promise<ProjectsApiResult>
 */
export async function fetchProjects(): Promise<GenericApiResult> {
  let loading = true;
  let error: string | null = null;
  let data: never[] = [];

  await getRequest("projects")
    .then((response) => {
      data = response.data;
      error = null;
      loading = false;
    })
    .catch((err) => {
      error = err.message;
      loading = false;
    });

  return {
    loading,
    error,
    data,
  };
}

/**
 * Fetches a single project by id
 * @param string|undefined id
 * @returns Promise<ProjectApiResult>
 */
export async function fetchProject(id: string | undefined): Promise<ProjectApiResult> {
  let loading = true;
  let error: string | null = null;
  let data: ProjectData | null = null;

  if (typeof id !== "string") {
    loading = false;
    error = "Project id missing from request";
    return {
      loading,
      error,
      data,
    };
  }

  await getRequest("projects/" + id)
    .then((response) => {
      data = response.data;
      error = null;
      loading = false;
    })
    .catch((err) => {
      error = err.message;
      loading = false;
    });

  return {
    loading,
    error,
    data,
  };
}

/**
 * Create a project
 * @param ProjectCreateVars input
 * @returns Promise<ProjectCreateData>
 */
export async function createProject(input: ProjectCreateVars): Promise<ProjectCreateData> {
  let result: undefined | Project = undefined,
    messages: string[] = [];

  await postRequest("projects", input)
    .then((response) => {
      const data = response.data.projectCreate;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.projectCreate;
      messages = data.messages;
    });

  return {
    projectCreate: {
      result: result,
      messages: messages,
    },
  };
}

/**
 * Update a project
 * @param ProjectUpdateVars input
 * @returns Promise<ProjectUpdate>
 */
export async function updateProject(
  id: string | undefined,
  input: ProjectUpdateVars
): Promise<ProjectUpdate> {
  let result: undefined | Project = undefined,
    messages: string[] = [];

  if (typeof id !== "string") {
    return {
      projectUpdate: {
        result: result,
        messages: [{ field: "base", message: "Id missing from request" }],
      },
    };
  }

  await patchRequest("projects/" + id, input)
    .then((response) => {
      const data = response.data.projectUpdate;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.projectUpdate;
      messages = data.messages;
    });

  return {
    projectUpdate: {
      result: result,
      messages: messages,
    },
  };
}

/**
 * Delete a single project by id
 * @param string|undefined id
 * @returns Promise<ProjectDeleteData>
 */
export async function deleteProject(id: string | undefined): Promise<ProjectDeleteData> {
  let result: undefined | Project = undefined,
    messages: string[] = [];

  if (typeof id !== "string") {
    messages.push("An error occurred while deleting the Project");
    return {
      projectDelete: {
        result: result,
        messages: messages,
      },
    };
  }

  await deleteRequest("projects/" + id)
    .then((response) => {
      const data = response.data.projectDelete;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.projectDelete;
      messages = data.messages;
    });

  return {
    projectDelete: {
      result: result,
      messages: messages,
    },
  };
}

/**
 * Prepare a survey respondent
 * @param ResponsePrepareVars input
 * @returns Promise<ResponsePrepareData>
 */
export async function prepareResponse(input: ResponsePrepareVars): Promise<ResponsePrepareData> {
  let result: undefined | Project = undefined,
    messages: string[] = [];

  await postRequest("project-response", input)
    .then((response) => {
      const data = response.data.responsePrepare;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.responsePrepare;
      messages = data.messages;
    });

  return {
    responsePrepare: {
      result: result,
      messages: messages,
    },
  };
}

/**
 * Finalize a survey respondent
 * @param ResponseFinalizeVars input
 * @returns Promise<ResponseFinalizeData>
 */
export async function finalizeResponse(input: ResponseFinalizeVars): Promise<ResponseFinalizeData> {
  let result: undefined | Project = undefined,
    messages: string[] = [];

  await patchRequest("project-response", input)
    .then((response) => {
      const data = response.data.responseFinalize;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.responseFinalize;
      messages = data.messages;
    });

  return {
    responseFinalize: {
      result: result,
      messages: messages,
    },
  };
}

/**
 * Batch update project responses
 * @param ProjectResponseStatusBatchUpdateVars input
 * @returns Promise<ProjectResponseStatusBatchUpdatedAt>
 */
export async function updateResponses(
  input: ProjectResponseStatusBatchUpdateVars
): Promise<ProjectResponseStatusBatchUpdatedAt> {
  let result: undefined | Project = undefined,
    messages: string[] = [];

  await patchRequest("project-responses", input)
    .then((response) => {
      const data = response.data.projectResponseStatusBatchUpdate;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.projectResponseStatusBatchUpdate;
      messages = data.messages;
    });

  return {
    projectResponseStatusBatchUpdate: {
      result: result,
      messages: messages,
    },
  };
}

/**
 * Submits pre survey answers
 * @param project_supplier_id 
 * @param answers 
 * @param respondent_id 
 * @returns 
 */
export async function submitPreSurvey(
  project_supplier_id: string,
  answers: PreSurveyAnswer[],
  respondent_id: string
): Promise<ProjectSupplierPreSurveySubmit> {
  let result: undefined | Project = undefined,
    messages: string[] = [];

  await postRequest(`project-suppliers/${project_supplier_id}/pre-survey`, {
    answers: answers,
    respondent_id: respondent_id,
  })
    .then((response) => {
      const data = response.data.ProjectSupplierPreSurveySubmit;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.ProjectSupplierPreSurveySubmit;
      messages = data.messages;
    });

  return {
    ProjectSupplierPreSurveySubmit: {
      result: result,
      messages: messages,
    },
  };
}

/**
 * Retrieves flagged respondent answers for a project supplier
 * @param project_supplier_id
 * @returns Promise<ProjectSupplierFlaggedAnswers>
 */
export async function getProjectSupplierFlaggedAnswers(
  project_supplier_id: string
): Promise<ProjectSupplierFlaggedAnswers> {
  let result: ProjectSupplierAnswer[] = [],
    messages: string[] = [];

  await getRequest(`project-suppliers/${project_supplier_id}/flagged-answers`)
    .then((response) => {
      const data = response.data.ProjectSupplierFlaggedAnswers;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.ProjectSupplierFlaggedAnswers;
      messages = data.messages;
    });

  return {
    ProjectSupplierFlaggedAnswers: {
      result: result,
      messages: messages,
    },
  };
}

/**
 * Sends the forensiq device data for a respondent
 * @param respondent_id
 * @param forensiq_data
 * @returns
 */
export async function updateRespondentDevice(
  respondent_id: string,
  forensiq_data: JsonObject
): Promise<RespondentDeviceForensiq> {
  let result = false,
    messages: string[] = [];

  await postRequest(`project-respondent/${respondent_id}/device-forensiq`, {
    data: JSON.stringify(forensiq_data),
  })
    .then((response) => {
      const data = response.data.RespondentDeviceForensiq;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.RespondentDeviceForensiq;
      messages = data.messages;
    });

  return {
    RespondentDeviceForensiq: {
      result: result,
      messages: messages,
    },
  };
}

export async function getRespondentDeviceForensiq(
  respondent_id: string
): Promise<ProjectResponseDeviceForensiq> {
  let result: RespondentDeviceForensiq | null = null,
    messages: string[] = [];

  await getRequest(`project-respondent/${respondent_id}/device-forensiq`)
    .then((response) => {
      const data = response.data.projectResponseDeviceForensiq;
      result = data.result || null;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.projectResponseDeviceForensiq;
      result = null;
      messages = data.messages;
    });

  return {
    projectResponseDeviceForensiq: {
      result: result,
      messages: messages,
    },
  };
}
