import isEmpty from "lodash/isEmpty";
import Form from "../../components/Profile/Edit";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { updateSelf } from "../../api/User";

const UserEditRoute: React.FC = () => {
  const onSubmit = async (values: UserUpdateVars) => {
    try {
      const response = await updateSelf(values);
      const { result, messages } = response.userUpdate;

      if (!isEmpty(messages)) {
        notify(messages);
      } else if (result) {
        notifySimple(["Details updated"], "success");
      }
    } catch (err) {
      notifySimple(["Error updating details"], "error");
    }
  };

  return <Form onSubmit={onSubmit} />;
};

export default UserEditRoute;
