import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import Snackbar from "./Snackbar";

export const notify = (errors: ApiError[], success = ""): void => {
  if (!isEmpty(errors)) {
    errors.forEach((error) => {
      Snackbar.error(
        startCase(error.field === "base" ? "" : error.field) + " " + (error.message || "")
      );
    });
  } else if (success && !isEmpty(success)) {
    Snackbar.success(success);
  }
};

export const notifySimple = (
  messages: string[],
  type: "error" | "success" | "info" | "warning"
): void => {
  if (!isEmpty(messages)) {
    messages.forEach((message) => {
      switch (type) {
        case "error":
          Snackbar.error(message);
          break;
        case "success":
          Snackbar.success(message);
          break;
        case "info":
          Snackbar.info(message);
          break;
        case "warning":
          Snackbar.warning(message);
          break;
      }
    });
  }
};
