import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Formik } from "formik";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import MainLayout from "../../themes/layouts/Main";
import Form from "../../components/Suppliers/Edit";
import Loader from "../../themes/components/Loader";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { fetchSupplier, updateSupplier } from "../../api/Supplier";

const validationSchema = Yup.object().shape({
  input: Yup.object().shape({
    name: Yup.string().min(2, "Too short!").max(30, "Too long!").required("Name is required"),
    endpoints: Yup.object().shape({
      completed: Yup.string()
        //.matches(urlPattern, "Please enter a valid URL")
        .required("This endpoint is required"),
      quota_full: Yup.string()
        //.matches(urlPattern, "Please enter a valid URL")
        .required("This endpoint is required"),
      screened_out: Yup.string()
        //.matches(urlPattern, "Please enter a valid URL")
        .required("This endpoint is required"),
      terminated: Yup.string()
        //.matches(urlPattern, "Please enter a valid URL")
        .required("This endpoint is required"),
    }),
    fields: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .matches(/[a-zA-Z0-9-_:]+$/, "Only alphanumeric characters are allowed")
          .max(64, "Too long!")
          .required("Field Name is required"),
        value: Yup.string()
          .matches(
            /[a-zA-Z0-9-_\[\]\%\{\}]+$/,
            "Only alphanumeric and special characters are allowed"
          )
          .max(64, "Too long!")
          .required("Field Value is required"),
      })
    ),
  }),
});

const SupplierEditRoute: React.FC = () => {
  const { id } = useParams<{ id: Supplier["id"] }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<SupplierData | null>(null);

  const fetchData = async () => {
    const result = await fetchSupplier(id);
    setLoading(result.loading);
    setError(result.error);
    setData(result.data);
  };

  const onSubmit = async (values: SupplierUpdateVars) => {
    try {
      if (typeof id !== "string") {
        notify([
          {
            field: "id",
            message: "Parameter is missing",
          },
        ]);
        return;
      }

      const response = await updateSupplier(id, values);
      const { result, messages } = response.supplierUpdate;

      if (!isEmpty(messages)) {
        notify(messages, "error");
      } else if (result) {
        notifySimple(["Supplier updated"], "success");
      }
    } catch (err) {
      notifySimple(["Error updating supplier"], "error");
    }
  };

  useEffect(() => {
    void fetchData();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  if (error || !data) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>
    );
  }

  const supplier = data.supplier;

  return (
    <MainLayout>
      <Formik<SupplierUpdateVars>
        onSubmit={onSubmit}
        initialValues={{
          id: supplier.id,
          input: {
            name: supplier.name,
            fields: supplier.fields,
            endpoints: supplier.endpoints,
          },
        }}
        validationSchema={validationSchema}
      >
        {() => <Form />}
      </Formik>
    </MainLayout>
  );
};

export default SupplierEditRoute;
