import { KeyboardEvent, useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import { Formik, FastField, Form } from "formik";
import { TextField } from "formik-material-ui";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as Yup from "yup";
import PasswordValidation from "../PasswordValidation";

import AuthLayout from "../../themes/layouts/Auth";
import useStyles from "./Styles";
import useButtonStyles from "../../styles/Buttons";

type Props = {
  onSubmit: (values: ResetPasswordSetNewVars, formikBag: unknown) => void | Promise<unknown>;
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Please enter your password"),
  passwordConfirmation: Yup.string()
    .required("Please enter the password again")
    .oneOf([Yup.ref("password"), null], "Passwords don't match"),
});

const ResetPasswordNewForm: React.FC<Props> = ({ onSubmit }) => {
  const params = useParams<{ token: string }>(),
    [password, setPassword] = useState("");

  const { classes } = useStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();

  return (
    <AuthLayout>
      <div>
        <Typography variant="h1">Set New Password</Typography>
        <Typography variant="subtitle1">
          Back to{" "}
          <Link component={NavLink} to="/user/login">
            Sign in
          </Link>
        </Typography>
        <Formik<ResetPasswordSetNewVars>
          validationSchema={validationSchema}
          initialValues={{ password: "", passwordConfirmation: "", code: params.token || "" }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <Form>
              <div className={classes.inputBox}>
                <FastField
                  component={TextField}
                  name="password"
                  label="New password"
                  fullWidth
                  variant="standard"
                  type="password"
                  onKeyUp={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                  autoFocus
                />
                <PasswordValidation password={password} />
              </div>
              <div className={classes.inputBox}>
                <FastField
                  component={TextField}
                  name="passwordConfirmation"
                  label="Repeat new password"
                  fullWidth
                  variant="standard"
                  type="password"
                  onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === "Enter") handleSubmit();
                  }}
                />
              </div>
              <Box display="flex" justifyContent="flex-end" pt={2} className={classes.formLinks}>
                <Button type="submit" className={cx(buttonClasses.blue, buttonClasses.large)}>
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};

export default ResetPasswordNewForm;
