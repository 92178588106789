import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Form from "./Form";

const SupplierCreate: React.FC = () => {
  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h1">New Supplier</Typography>
      </Box>
      <Form />
    </div>
  );
};

export default SupplierCreate;
