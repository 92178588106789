import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Form from "./Form";

type Props = {
  suppliers: Supplier[];
};

const ProjectCreate: React.FC<Props> = ({ suppliers }) => {
  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h1">New Project</Typography>
      </Box>
      <Form suppliers={suppliers} />
    </div>
  );
};

export default ProjectCreate;
