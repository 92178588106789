import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";

import MainLayout from "../../themes/layouts/Main";
import Form from "../../components/Projects/Create";
import Loader from "../../themes/components/Loader";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { fetchSuppliers } from "../../api/Supplier";
import { createProject } from "../../api/Project";

const validationSchema = Yup.object().shape({
  input: Yup.object().shape({
    name: Yup.string().min(2, "Too short!").max(255, "Too long!").required("Name is required"),
    status: Yup.string().required("Status is required"),
  }),
});

const ProjectCreateRoute: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<never[]>([]);

  const fetchData = async () => {
    const result = await fetchSuppliers();
    setLoading(result.loading);
    setError(result.error);
    setData(result.data);
  };

  const onSubmit = async (values: ProjectCreateVars) => {
    try {
      const response = await createProject(values);
      const { result, messages } = response.projectCreate;

      if (!isEmpty(messages)) {
        notify(messages, "error");
      } else if (result) {
        notifySimple(["Project created"], "success");
        navigate("/");
      }
    } catch (err) {
      notifySimple(["Error creating project"], "error");
    }
  };

  useEffect(() => {
    void fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error || !data) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>
    );
  }

  return (
    <MainLayout>
      <Formik<ProjectCreateVars>
        onSubmit={onSubmit}
        initialValues={{
          input: {
            name: "",
            status: "PENDING",
            suppliers: [],
          },
        }}
        validationSchema={validationSchema}
      >
        {() => <Form suppliers={data} />}
      </Formik>
    </MainLayout>
  );
};

export default ProjectCreateRoute;
