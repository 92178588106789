import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Form from "./Form";

const SupplierEdit: React.FC = () => {
  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h1">Edit Supplier</Typography>
      </Box>
      <Form />
    </div>
  );
};

export default SupplierEdit;
