import { makeStyles } from "./index";

export default makeStyles()((theme) => ({
  green: {
    backgroundColor: "#8CD99E",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#8CD99E",
      filter: "brightness(95%)",
    },
  },
  grey: {
    backgroundColor: "#F8F8FD",
    "& .MuiButton-label": {
      color: "#6471b1",
    },
    "&:hover": {
      backgroundColor: "#F8F8FD",
      filter: "brightness(95%)",
    },
  },
  blue: {
    backgroundColor: "#4180FF",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#4180FF",
      filter: "brightness(95%)",
    },
  },
  red: {
    backgroundColor: "#F45B54",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#F45B54",
      filter: "brightness(95%)",
    },
  },
  white: {
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#FFF",
      filter: "brightness(95%)",
    },
    "& .MuiButton-label": {
      color: "#45464B",
    },
  },
  whiteOutlined: {
    whiteSpace: "nowrap",
    backgroundColor: "#fff",
    border: "1px solid #E6E8EC",
    "&:hover": {
      backgroundColor: "#FFF",
      filter: "brightness(95%)",
    },
  },
  tiny: {
    whiteSpace: "nowrap",
    "&.MuiButton-root": {
      padding: "2px 6px",
      fontSize: 11,
    },
  },
  small: {
    whiteSpace: "nowrap",
    "&.MuiButton-root": {
      padding: "4px 10px",
    },
  },
  large: {
    whiteSpace: "nowrap",
    minWidth: 160,
    "&.MuiButton-text": {
      padding: "8px 40px",
    },
  },
  largeSecondary: {
    whiteSpace: "nowrap",
    fontSize: 13,
    textTransform: "none",
  },
  transparentWhite: {
    whiteSpace: "nowrap",
    backgroundColor: "transparent",
    border: "1px solid #ffffff",
    borderRadius: 4,
    color: "#ffffff",
    fontWeight: "bold",
    height: 30,
    textAlign: "center",
    fontSize: 12,
    cursor: "pointer",
  },
  iconButton: {
    whiteSpace: "nowrap",
    "& svg": {
      marginRight: theme.spacing(1),
      height: 20,
    },
  },
  iconButtonGreen: {
    whiteSpace: "nowrap",
    //backgroundColor: "#8CD99E",
    "& .icon": {
      color: "#8CD99E",
      fill: "#8CD99E",
    },
    "&:hover": {
      //backgroundColor: "#8CD99E",
      filter: "brightness(95%)",
    },
    "&:hover .icon": {
      color: "#8CD99E",
      fill: "#8CD99E",
    },
  },
  tagOutlined: {
    minWidth: 70,
    border: "1px solid #8490CE",
    borderRadius: 20,
    textTransform: "none",
    "& .MuiButton-label": {
      color: "#8490CE",
    },
    "&.active": {
      backgroundColor: "#8490CE",
      "& .MuiButton-label": {
        color: "#FFF",
      },
    },
  },
  tag: {
    minWidth: 60,
    borderRadius: 20,
    padding: `2px ${theme.spacing(1)}`,
    textTransform: "none",
    backgroundColor: "#8490CE",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&:focus, &:visited, &:active, &:hover": {
      backgroundColor: "#8490CE",
    },
    "& .MuiButton-label": {
      color: "#FFF",
    },
  },
  pure: {
    display: "flex",
    alignItems: "center",
    border: "none",
    outline: "none",
    margin: 0,
    padding: 0,
    backgroundColor: "transparent",
    cursor: "pointer",
    fontSize: "inherit",
    color: "inherit",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  disabled: {
    backgroundColor: "#E0E0E0",
  },
}));
