import { useState } from "react";
import isEmpty from "lodash/isEmpty";
import Signup from "../../components/Auth/Signup";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { createUser } from "../../api/User";
import zxcvbn from "zxcvbn";

const UserSignupRoute: React.FC = () => {
  const [accountCreated, setAccountCreated] = useState(false);

  const onSubmit = async (values: CreateUserVars) => {
    try {
      const passwordScore = zxcvbn(values.input.password);
      if (passwordScore.score < 4) {
        notifySimple(["The password must be rated strong!"], "error");
        return;
      }
      const response = await createUser(values);
      const { result, messages } = response.userCreate;

      if (!isEmpty(messages)) {
        notify(messages, "error");
      } else if (result) {
        setAccountCreated(true);
        notifySimple(["User created"], "success");
      }
    } catch (err) {
      notifySimple(["Error creating user"], "error");
    }
  };

  return <Signup onSubmit={onSubmit} accountCreated={accountCreated} />;
};

export default UserSignupRoute;
