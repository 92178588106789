import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import MainLayout from "../../themes/layouts/Main";
import Form from "../../components/Projects/Edit";
import Loader from "../../themes/components/Loader";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { fetchProject, updateProject } from "../../api/Project";

const validationSchema = Yup.object().shape({
  input: Yup.object().shape({
    name: Yup.string().min(2, "Too short!").max(255, "Too long!").required("Name is required"),
  }),
});

const ProjectEditRoute: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: Project["id"] }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<ProjectData | null>(null);

  const fetchData = async () => {
    const result = await fetchProject(id);
    setLoading(result.loading);
    setError(result.error);
    setData(result.data);
  };

  const onSubmit = async (values: ProjectUpdateVars) => {
    try {
      if (typeof id !== "string") {
        notify([
          {
            field: "id",
            message: "Parameter is missing",
          },
        ]);
        return;
      }

      const response = await updateProject(id, values);
      const { result, messages } = response.projectUpdate;

      if (!isEmpty(messages)) {
        notify(messages, "error");
      } else if (result) {
        notifySimple(["Project updated"], "success");
        navigate("/project/" + id + "/view");
      }
    } catch (err) {
      notifySimple(["Error updating project"], "error");
    }
  };

  useEffect(() => {
    void fetchData();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  if (error || !data) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>
    );
  }

  if (data.project && data.suppliers) {
    const project = data.project;
    const suppliers = data.suppliers;

    return (
      <MainLayout>
        <Formik<ProjectUpdateVars>
          onSubmit={onSubmit}
          initialValues={{
            id: project.id,
            input: {
              name: project.name,
              status: project.status,
              suppliers: project.suppliers.map(
                (supplier) =>
                  ({
                    id: supplier.id,
                    supplier_id: supplier.source.id,
                    limit: supplier.limit,
                    survey_url: supplier.survey_url,
                    opening_tag: supplier.opening_tag || "{{",
                    closing_tag: supplier.closing_tag || "}}",
                    respondent_key: supplier.respondent_key || "",
                    status_key: supplier.status_key || "",
                    questions: supplier.questions,
                    pre_survey_tool: supplier.pre_survey_tool || "",
                    pre_survey_id: supplier.pre_survey_id || "",
                    description: supplier.description || "",
                    fields: supplier.fields.map((field) => ({
                      id: field.id,
                      name: field.name,
                      value: field.value,
                      required: field.required,
                      supplier_field_id: field.source?.id,
                    })),
                  } as ProjectSupplierInput)
              ),
            },
          }}
          validationSchema={validationSchema}
        >
          {() => <Form suppliers={suppliers} />}
        </Formik>
      </MainLayout>
    );
  }

  return <Loader />;
};

export default ProjectEditRoute;
