import { useNavigate } from "react-router-dom";
import { useFormikContext, Field, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Tooltip from "../../themes/components/Tooltip";
import useSharedStyles from "../../styles/Shared";
import useButtonStyles from "../../styles/Buttons";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const SupplierForm: React.FC = () => {
  const navigate = useNavigate();
  const { values, setFieldValue, handleSubmit } = useFormikContext<SupplierCreateVars>();

  const { classes } = useSharedStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();

  const renderFormButtons = () => (
    <div>
      <Button
        onClick={() => navigate("/suppliers")}
        className={cx(buttonClasses.largeSecondary, buttonClasses.red)}
        style={{ marginRight: 8 }}
      >
        Cancel
      </Button>
      <Button
        onClick={() => handleSubmit()}
        className={cx(buttonClasses.largeSecondary, buttonClasses.blue)}
      >
        Save
      </Button>
    </div>
  );

  const handleFieldRequiredClick = (fieldIdx: number) => {
    setFieldValue(`input.fields.${fieldIdx}.required`, !values.input.fields[fieldIdx].required);
  };

  return (
    <div className={classes.contentBox}>
      <div className={classes.inputBox}>
        <Field
          component={TextField}
          name="input.name"
          label="Name"
          variant="standard"
          fullWidth
          autoFocus
        />
      </div>
      <Typography variant="h4">Endpoints</Typography>
      <Typography variant="subtitle1">
        Provide supplier endpoints for each response status type.
        <br />
        For any dynamic URL variable values use{" "}
        <code>
          {"{{"} {"}}"}
        </code>{" "}
        for starting and ending tags.
        <br />
        Between the <code>{"{{ }}"}</code> put the <strong>field name</strong> which value you want
        to pass, for example:
        <br />
        if you define field with name <code>lucid_rid</code> and value <code>[%RID%]</code> then
        your "Complete" endpoint may look like this:
        <br />
        <code>{"https://notch.insights.supply/cb?token=XYZ&RID={{lucid_rid}}"}</code>
      </Typography>
      <div className={classes.inputBox}>
        <Field
          component={TextField}
          name="input.endpoints.completed"
          label="Complete"
          variant="standard"
          fullWidth
        />
      </div>
      <div className={classes.inputBox}>
        <Field
          component={TextField}
          name="input.endpoints.quota_full"
          label="Quota Full"
          variant="standard"
          fullWidth
        />
      </div>
      <div className={classes.inputBox}>
        <Field
          component={TextField}
          name="input.endpoints.screened_out"
          label="Screened Out"
          variant="standard"
          fullWidth
        />
      </div>
      <div className={classes.inputBox}>
        <Field
          component={TextField}
          name="input.endpoints.terminated"
          label="Quality Termination"
          variant="standard"
          fullWidth
        />
      </div>

      <FieldArray
        name="input.fields"
        render={(arrayHelpers) => (
          <>
            <Typography variant="h4">Fields</Typography>
            {values.input.fields.map((field, fIdx) =>
              field.deleted ? (
                <div key={fIdx}></div>
              ) : (
                <Box key={fIdx} className={classes.inputBox} display="flex" alignItems="center">
                  <Field
                    component={TextField}
                    name={`input.fields.${fIdx}.name`}
                    label="Field Name"
                    variant="standard"
                    fullWidth
                    style={{ marginRight: 8 }}
                  />
                  <Field
                    component={TextField}
                    name={`input.fields.${fIdx}.value`}
                    label="Field Value"
                    variant="standard"
                    fullWidth
                    style={{ marginRight: 8 }}
                  />
                  <FormControlLabel
                    label="Required"
                    control={
                      <Checkbox color="secondary" checked={values.input.fields[fIdx].required} />
                    }
                    onChange={() => handleFieldRequiredClick(fIdx)}
                  />
                  <Tooltip title="Delete" placement="top">
                    <IconButton
                      onClick={() => {
                        if (!field.id) {
                          arrayHelpers.remove(fIdx);
                        } else {
                          setFieldValue(`input.fields.${fIdx}.deleted`, true);
                        }
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )
            )}
            <Button
              className={cx(buttonClasses.small, buttonClasses.iconButton, buttonClasses.blue)}
              onClick={() => arrayHelpers.push({ name: "", value: "", required: false })}
            >
              <ControlPointIcon /> Add a Field
            </Button>
          </>
        )}
      />

      <Box display="flex" justifyContent="flex-end">
        {renderFormButtons()}
      </Box>
    </div>
  );
};

export default SupplierForm;
