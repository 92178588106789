import { makeStyles } from "./index";

export default makeStyles()((theme) => ({
  root: {
    maxHeight: "60%",
    overflow: "hidden",
  },
  arrow: {
    overflow: "visible",
    marginTop: 10,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 4,

    "&:before": {
      content: "''",
      position: "absolute",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderColor: "transparent transparent #fff transparent",
      borderWidth: "0 12px 12px 0px",
      left: 0,
      right: "auto",
      top: -10,
      clipPath: "polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0 0)",
    },
  },
  arrowRight: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 4,

    "&:before": {
      borderWidth: "0 0px 12px 12px",
      right: 0,
      left: "auto",
    },
  },
  arrowBottom: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    marginTop: 0,
    marginBottom: 10,
    "&:before": {
      bottom: -10,
      top: "auto",
      borderWidth: "12px 12px 0 0",
      borderColor: "#fff transparent transparent #fff",
      clipPath: "none",
    },
  },
  contained: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  wide: {
    maxWidth: "50%",
  },
  close: {
    width: 14,
    height: 14,
    color: "#20252A",
  },
  scrollable: {
    overflowY: "auto",
  },
}));
