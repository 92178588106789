//import { NavLink } from "react-router-dom";

import { makeStyles } from "../../styles";

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 12,
    color: theme.palette.text.secondary,
    width: "100%",
  },
  links: {
    display: "flex",
    "& > *": {
      paddingLeft: theme.spacing(2),
    },
    "& a": {
      color: theme.palette.secondary.main,
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

const Footer: React.FC = () => {
  const { classes } = useStyles();

  const today = new Date();
  return (
    <div className={classes.root}>
      <nav>Copyright &copy; obsrvnow.com {today.getFullYear()}</nav>
      <div className={classes.links}>
        {/*
        <NavLink to="/terms-conditions" onClick={() => window.scrollTo(0, 0)}>
          Terms and Conditions
        </NavLink>
        <NavLink to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>
          Privacy Policy
        </NavLink>
        */}
      </div>
    </div>
  );
};

export default Footer;
