import { useContext } from "react";

import { AccountContext } from "../../contexts/CurrentUser";
import { makeStyles } from "../../styles";

const useStyles = makeStyles()((theme) => ({
  avatar: {
    width: 32,
    height: 32,
    borderRadius: 16,
    overflow: "hidden",
    border: `1px solid ${theme.palette.text.secondary}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 18,
    fontWeight: 600,
    textTransform: "uppercase",
  },
}));

const UserAvatar: React.FC = () => {
  const currentUser = useContext(AccountContext);
  const { classes } = useStyles();

  return <div className={classes.avatar}>{currentUser.account?.first_name[0]}</div>;
};

export default UserAvatar;
