import { getRespondentDeviceForensiq } from "../../api/Project";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../themes/components/Loader";
import { notify } from "../../themes/components/Notifications";
import MainLayout from "../../themes/layouts/Main";
import { isEmpty } from "lodash";
import Box from "@mui/material/Box";

const DeviceForensiq: React.FC = () => {
  const { id } = useParams(),
    [data, setData] = useState<ProjectResponse | null>(null),
    [loading, setLoading] = useState<boolean>(true),
    [error, setError] = useState<boolean>(false);

  const getData = async () => {
    if (!id) return;
    const response: ProjectResponseDeviceForensiq = await getRespondentDeviceForensiq(id);
    const { result, messages } = response.projectResponseDeviceForensiq;
    if (!isEmpty(messages)) {
      notify(messages, "error");
      setError(true);
    } else if (result) {
      setData(result);
    }
    setLoading(false);
  };

  const getDeviceForensiq = () => {
    if (!data?.device_forensiq) return null;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(data?.device_forensiq) || null;
  };

  const getKey = (key: string): string => {
    return key
      .replace(/([A-Z])/g, " $1") // Insert a space before each uppercase letter
      .replace(/(\d+)/g, " $1 ") // Add spaces around numbers
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
      .trim();
  };

  const getValue = (value: any): string => {
    if (null === value) return "-";
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return value.toString();
  };

  useEffect(() => {
    if (!data) {
      void getData();
    }
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <MainLayout>
         <Box style={{background: 'white', padding: '2rem'}}>
          <h1 style={{marginTop: 0}}>Device Forensiq: {data?.id}</h1>
          <p>An unexpected error has occurred.</p>
        </Box>
      </MainLayout>
    );
  }

  const deviceForensiq = getDeviceForensiq();

  if (!deviceForensiq || !deviceForensiq?.forensic) {
    return (
      <MainLayout>
        <Box style={{background: 'white', padding: '2rem'}}>
          <h1 style={{marginTop: 0}}>Device Forensiq: {data?.id}</h1>
          <p>There isn't any Device Forensiq data for this respondent.</p>
        </Box>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <h1 style={{marginTop: 0}}>Device Forensiq: {data?.id}</h1>
      <Box style={{background: 'white', padding: '2rem'}}>
        <ul>
          {Object.entries(deviceForensiq.forensic).map(([key, value]) => (
            <li key={key}>
              {value && typeof value === "object" && Object.keys(value).length ? (
                <ul style={{ paddingLeft: "2rem" }}>
                  {Object.entries(value).map(([childKey, childValue]) => (
                    <li key={childKey}>
                      <strong>{getKey(childKey)}</strong>: {getValue(childValue)}
                    </li>
                  ))}
                </ul>
              ) : (
                <span>
                  <strong>{getKey(key)}</strong>: {getValue(value)}
                </span>
              )}
            </li>
          ))}
        </ul>
      </Box>
    </MainLayout>
  );
};

export default DeviceForensiq;
