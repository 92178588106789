import { isMobileOnly } from "react-device-detect";
import { makeStyles } from "./index";

export default makeStyles()((theme) => ({
  mainCol: {
    maxWidth: "calc(100% - 300px)",
  },
  contentBox: {
    backgroundColor: "#fff",
    padding: theme.spacing(2),
  },
  inputBox: {
    paddingBottom: theme.spacing(2),
  },
  contentTable: {
    "& tfoot td": {
      borderBottom: "none",
    },
  },
  mobileContentTable: {
    "& tr": {
      display: "flex",
      flexWrap: "wrap",
      "& td": {
        display: "block",
        width: "100%",
        borderBottom: "none",
        "&.settings-cell": {
          paddingBottom: 0,
        },
        "&.name-cell": {
          paddingTop: 0,
        },
        "&:last-child": {
          borderBottom: "1px solid #c3c3c3",
        },
      },
    },
  },
  responsesContentTable: {
    "& td": {
      whiteSpace: "nowrap",
    },
  },
  itemWrapper: {
    display: "flex",
    alignItems: "center",
    "*:first-of-type": {
      marginRight: theme.spacing(1),
    },
  },
  itemName: {
    fontSize: 14,
    fontWeight: 600,
    "&.link": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      transition: "all 0.3s",
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
  },
  itemSubName: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
  },
  settingsButton: {
    color: theme.palette.text.secondary,
  },
  dropdownMenu: {
    padding: theme.spacing(0.5),
  },
  dropdownMenuItem: {
    fontSize: 12,
    fontWeight: 500,

    "&.red": {
      color: "rgb(244, 91, 84)",
    },

    "& svg": {
      width: 18,
      marginRight: theme.spacing(1.5),
      color: theme.palette.text.secondary,
    },
  },

  itemStats: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: isMobileOnly ? "wrap" : "nowrap",
  },
  itemStatsElement: {
    position: "relative",
    display: isMobileOnly ? "flex" : "block",
    alignItems: "center",
    flexBasis: isMobileOnly ? "50%" : "20%",
    flexGrow: isMobileOnly ? 1 : 0,
    textAlign: isMobileOnly ? "left" : "center",
    padding: isMobileOnly ? theme.spacing(0, 2, 1.5, 0) : theme.spacing(0, 2, 1.5),
    fontSize: 11,
    textTransform: "uppercase",
    "& > div:last-child": {
      whiteSpace: "nowrap",
      paddingLeft: isMobileOnly ? theme.spacing(1) : 0,
    },
  },
  itemStatsNumber: {
    fontWeight: 600,
    fontSize: 18,
    "&.total": {
      fontSize: 26,
    },
    "&.negative": {
      color: "rgb(244, 91, 84)",
    },
    "&.positive": {
      color: "rgb(35, 182, 154)",
    },
    "&.neutral": {
      color: "rgb(252, 183, 43)",
    },
  },
  itemStatsSub: {
    fontSize: 12,
    color: theme.palette.grey[500],
    display: "inline-block",
    paddingLeft: theme.spacing(0.5),
    position: "absolute",
    "&.static": {
      position: "static",
    },
  },

  tag: {
    display: "inline-block",
    fontSize: 12,
    fontWeight: 600,
    padding: theme.spacing(0.5, 1),
    marginLeft: theme.spacing(2),
  },
  tagPositive: {
    backgroundColor: "rgba(35, 182, 154, 0.1)",
    color: "rgb(35, 182, 154)",
  },
  tagNeutral: {
    backgroundColor: "rgba(252, 183, 43, 0.1)",
    color: "rgb(252, 183, 43)",
  },
  tagNegative: {
    backgroundColor: "rgba(244, 91, 84, 0.1)",
    color: "rgb(244, 91, 84)",
  },

  pagination: {
    margin: theme.spacing(1, 0),
    "& .MuiPagination-ul": {
      flexWrap: "nowrap",
      justifyContent: "center",
    },
    "& .MuiButtonBase-root": {
      margin: 0,
    },
    "& .MuiPaginationItem-root": {
      minWidth: 24,
      height: 24,
      fontSize: 12,
    },
  },
}));
