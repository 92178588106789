import { useState } from "react";
import { Formik, Form, FastField } from "formik";
import Button from "@mui/material/Button";
import { TextField } from "formik-material-ui";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";

import ProfileLayout from "../../themes/layouts/Profile";
import PasswordValidation from "../PasswordValidation";
import useStyles from "./Styles";
import useButtonStyles from "../../styles/Buttons";

type Props = {
  onSubmit: (values: UserPasswordChangeVars, formikBag: unknown) => void | Promise<unknown>;
};

const ChangePassword: React.FC<Props> = ({ onSubmit }) => {
  const { classes } = useStyles();
  const { classes: buttonClasses } = useButtonStyles();
  const [password, setPassword] = useState("");

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current password is required"),
    password: Yup.string().required("Please enter new password"),
  });

  return (
    <ProfileLayout>
      <div>
        <Typography variant="h3">Change Password</Typography>
        <Formik<UserPasswordChangeVars>
          validationSchema={validationSchema}
          initialValues={{ password: "", currentPassword: "" }}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <div className={classes.mainCol}>
                <div className={classes.inputBox}>
                  <FastField
                    name="currentPassword"
                    component={TextField}
                    label="Current password"
                    type="password"
                    fullWidth
                    variant="standard"
                  />
                </div>
                <div className={classes.inputBox}>
                  <FastField
                    name="password"
                    component={TextField}
                    label="New password"
                    type="password"
                    fullWidth
                    variant="standard"
                    onKeyUp={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                  />
                  <PasswordValidation password={password} />
                </div>
              </div>
              <Button type="submit" className={buttonClasses.blue}>
                Save Changes
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </ProfileLayout>
  );
};

export default ChangePassword;
