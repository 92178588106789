import { useState, KeyboardEvent } from "react";
import { NavLink } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";

import AuthLayout from "../../themes/layouts/Auth";
import useStyles from "./Styles";
import useButtonStyles from "../../styles/Buttons";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type Props = {
  onSubmit: (values: LoginVars, formikBag: unknown) => void | Promise<unknown>;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email").required("Email is required"),
  password: Yup.string().required("Please enter your password"),
});

const Login: React.FC<Props> = ({ onSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);

  const { classes } = useStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();

  const handleShowPasswordClick = () => setShowPassword((prev) => !prev);

  return (
    <AuthLayout>
      <Typography variant="h1">Sign in</Typography>

      <Typography variant="subtitle1">
        New user?{" "}
        <Link component={NavLink} to="/user/signup">
          Create an account
        </Link>
      </Typography>

      <Formik<LoginVars>
        validationSchema={validationSchema}
        initialValues={{ email: "", password: "" }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Form>
            <div className={classes.inputBox}>
              <Field
                component={TextField}
                label="Email"
                fullWidth
                autoFocus
                variant="standard"
                type="email"
                name="email"
              />
            </div>
            <div className={classes.inputBox}>
              <Field
                component={TextField}
                label="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                variant="standard"
                name="password"
                onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPasswordClick}
                        className={classes.showPasswordBtn}
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Button type="submit" fullWidth className={cx(buttonClasses.blue, buttonClasses.large)}>
              Sign In
            </Button>
          </Form>
        )}
      </Formik>

      <Box pt={2} className={classes.formLinks}>
        <Link component={NavLink} to="/user/reset-password">
          Forgot password?
        </Link>
        <Link component={NavLink} to="/user/resend-activation">
          Resend activation email?
        </Link>
      </Box>
    </AuthLayout>
  );
};

export default Login;
