import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";
import MainLayout from "../../themes/layouts/Main";
import Form from "../../components/Suppliers/Create";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { createSupplier } from "../../api/Supplier";

const validationSchema = Yup.object().shape({
  input: Yup.object().shape({
    name: Yup.string().min(2, "Too short!").max(30, "Too long!").required("Name is required"),
    endpoints: Yup.object().shape({
      completed: Yup.string()
        //.matches(urlPattern, "Please enter a valid URL")
        .required("This endpoint is required"),
      quota_full: Yup.string()
        //.matches(urlPattern, "Please enter a valid URL")
        .required("This endpoint is required"),
      screened_out: Yup.string()
        //.matches(urlPattern, "Please enter a valid URL")
        .required("This endpoint is required"),
      terminated: Yup.string()
        //.matches(urlPattern, "Please enter a valid URL")
        .required("This endpoint is required"),
    }),
    fields: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .matches(/[a-zA-Z0-9-_:]+$/, "Only alphanumeric characters are allowed")
          .max(64, "Too long!")
          .required("Field Name is required"),
        value: Yup.string()
          .matches(
            /[a-zA-Z0-9-_\[\]\%\{\}]+$/,
            "Only alphanumeric and special characters are allowed"
          )
          .max(64, "Too long!")
          .required("Field Value is required"),
      })
    ),
  }),
});

const SupplierCreateRoute: React.FC = () => {
  const navigate = useNavigate();

  const onSubmit = async (values: SupplierCreateVars) => {
    try {
      const response = await createSupplier(values);
      const { result, messages } = response.supplierCreate;

      if (!isEmpty(messages)) {
        notify(messages, "error");
      } else if (result) {
        notifySimple(["Supplier created"], "success");
        navigate("/suppliers");
      }
    } catch (err) {
      notifySimple(["Error creating supplier"], "error");
    }
  };

  return (
    <MainLayout>
      <Formik<SupplierCreateVars>
        onSubmit={onSubmit}
        initialValues={{
          input: {
            name: "",
            fields: [],
            endpoints: {
              completed: "",
              quota_full: "",
              screened_out: "",
              terminated: "",
            },
          },
        }}
        validationSchema={validationSchema}
      >
        {() => <Form />}
      </Formik>
    </MainLayout>
  );
};

export default SupplierCreateRoute;
