import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Pagination from "@mui/material/Pagination";
import isEmpty from "lodash/isEmpty";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import orderBy from "lodash/orderBy";
import ConfirmationDialog from "../../themes/components/ConfirmationDialog";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { getLocalTime, dateFormat } from "../../utils/Time";
import { usePagination } from "../../hooks/usePagination";
import useSharedStyles from "../../styles/Shared";
import useButtonStyles from "../../styles/Buttons";
import usePopoverStyles from "../../styles/Popovers";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import SettingsIcon from "@mui/icons-material/Settings";
import { deleteSupplier } from "../../api/Supplier";

type Props = {
  suppliers: Supplier[];
  refetch: () => void;
};

const SuppliersList: React.FC<Props> = ({ suppliers, refetch }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [itemEdit, setItemEdit] = useState<Supplier | null>(null);
  const [itemDelete, setItemDelete] = useState<string | null>(null);
  const [searchPhrase, setSearchPhrase] = useState("");

  const { classes } = useSharedStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();
  const { classes: popoverClasses } = usePopoverStyles();

  const perPage = 10;

  const { currentPage, pagesCount, offset, onPageChange } = usePagination(
    suppliers.length,
    perPage,
    true
  );

  const onDeleteSubmit = async (values: SupplierDeleteVars) => {
    try {
      const response = await deleteSupplier(values.id);
      const { messages, result } = response.supplierDelete;

      if (!isEmpty(messages)) {
        notify(messages);
      } else if (result) {
        notifySimple(["Supplier deleted"], "success");
        refetch();
      }
    } catch (err) {
      notifySimple(["Error deleting Supplier"], "error");
    }
  };

  const generateSettingsCell = (supplier: Supplier) => (
    <TableCell align="right" width={50} className="settings-cell">
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setItemEdit(supplier);
        }}
        className={classes.settingsButton}
      >
        <SettingsIcon />
      </IconButton>
    </TableCell>
  );

  const updateSearchPhrase = (newSearchPhrase: string) => {
    onPageChange(1);
    setSearchPhrase(newSearchPhrase);
  };

  const filteredSuppliers = orderBy(
    suppliers.filter(
      (supplier) => supplier.name.toLowerCase().indexOf(searchPhrase.toLowerCase()) !== -1
    ),
    [(el) => el.name.toLowerCase().trim()],
    ["asc"]
  );

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h1">Suppliers</Typography>
        <Autocomplete
          sx={{ width: isMobileOnly ? "100%" : 300, paddingRight: 2, paddingBottom: 1 }}
          freeSolo
          autoSelect={false}
          options={orderBy(suppliers, [(el) => el.name.toLowerCase().trim()], ["asc"]).map(
            (supplier) => supplier.name
          )}
          value={searchPhrase}
          onChange={(_, v) => {
            updateSearchPhrase(v || "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Suppliers"
              variant="standard"
              autoFocus
              onChange={(e) => updateSearchPhrase(e.target.value)}
            />
          )}
        />
        <Button
          className={cx(buttonClasses.blue, buttonClasses.iconButton)}
          component={NavLink}
          to="/supplier/create"
        >
          <ControlPointIcon /> Add a Supplier
        </Button>
      </Box>

      {isEmpty(suppliers) ? (
        <Alert severity="info">
          <AlertTitle>No available Suppliers</AlertTitle>
          Use the button on the right to add a new Supplier
        </Alert>
      ) : (
        <div className={classes.contentBox}>
          <Table className={cx(classes.contentTable, isMobileOnly && classes.mobileContentTable)}>
            <TableBody>
              {filteredSuppliers.slice(offset, offset + perPage).map((supplier) => (
                <TableRow key={supplier.id}>
                  {isMobileOnly && generateSettingsCell(supplier)}
                  <TableCell className="name-cell">
                    <div className={classes.itemWrapper}>
                      <div>
                        <NavLink
                          className={cx(classes.itemName, "link")}
                          to={`/supplier/${supplier.id}/edit`}
                        >
                          {supplier.name}
                        </NavLink>
                        <div className={classes.itemSubName}>
                          Created: <i>{getLocalTime(supplier.inserted_at).format(dateFormat)}</i>
                          {supplier.updated_at !== supplier.inserted_at && (
                            <>
                              <br />
                              Updated: <i>{getLocalTime(supplier.updated_at).format(dateFormat)}</i>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div>
                      <strong>Complete: </strong>
                      {supplier.endpoints.completed}
                    </div>
                    <div>
                      <strong>Quota Full: </strong>
                      {supplier.endpoints.quota_full}
                    </div>
                    <div>
                      <strong>Screened Out: </strong>
                      {supplier.endpoints.screened_out}
                    </div>
                    <div>
                      <strong>Termination: </strong>
                      {supplier.endpoints.terminated}
                    </div>
                  </TableCell>
                  <TableCell align="right">{supplier.fields.length} field(s) defined</TableCell>
                  {!isMobileOnly && generateSettingsCell(supplier)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {suppliers.length > perPage && (
            <Pagination
              className={classes.pagination}
              size="small"
              count={pagesCount}
              page={currentPage}
              onChange={(event, page) => {
                if (page === currentPage) {
                  return;
                }

                onPageChange(page);
              }}
            />
          )}
        </div>
      )}

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl && itemEdit)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        disableScrollLock
        PaperProps={{
          className: cx(popoverClasses.arrow, popoverClasses.arrowRight),
        }}
      >
        <List className={classes.dropdownMenu}>
          <ListItem
            button
            onClick={() => {
              setAnchorEl(null);
              navigate(`/supplier/${itemEdit?.id}/edit`);
            }}
            className={classes.dropdownMenuItem}
          >
            Edit
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setAnchorEl(null);
              setItemDelete(itemEdit?.id || null);
            }}
            className={cx(classes.dropdownMenuItem, "red")}
          >
            Delete
          </ListItem>
        </List>
      </Popover>

      <ConfirmationDialog
        show={Boolean(itemDelete)}
        title="Are you sure?"
        description="This supplier will be permanently deleted"
        okButton="Confirm"
        cancelButton="Cancel"
        okAction={() => {
          if (itemDelete) {
            void onDeleteSubmit({ id: itemDelete });
          }
        }}
        closeAction={() => setItemDelete(null)}
      />
    </div>
  );
};

export default SuppliersList;
