import React, { createContext, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { fetchUser, logout } from "../api/User";
import { userToken } from "../utils/Cookies";

type ContextProps = {
  account: UserAccount | null;
  accountRefetch: () => Promise<void> | void;
};

export const AccountContext = createContext({} as ContextProps);

export const AccountProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<UserAccount | null>(null);

  const logUserOut = async () => {
    const logged_out = await logout();
    if (!logged_out) {
      setError({ name: "logout", message: "An error occurred while logging out" });
      return;
    }
    location.href = "/user/login";
  };

  const getUser = async () => {
    const response = await fetchUser();
    setLoading(response.loading);
    if (!response.data && response.error) {
      if (location.href !== "/user/login" && userToken.read()) {
        userToken.delete();
        location.href = "/user/login";
        return;
      }
      return;
    }
    setData(response.data.result as UserAccount);
  };

  useEffect(() => {
    void getUser();
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (error || (isEmpty(userToken.read()) && isEmpty(data))) {
      void logUserOut();
    }
  }, [data, error]);

  if (loading) {
    return null;
  }

  const accountCtx = {
    account: data,
    accountRefetch: getUser,
  };

  return <AccountContext.Provider value={accountCtx}>{children}</AccountContext.Provider>;
};

export const { Consumer } = AccountContext;
