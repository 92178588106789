import { useState } from "react";

type Item = {
  id: string;
};

type UseSelectAll = {
  selectedList: string[];
  isChecked: (id: string) => boolean | undefined;
  selectedCount: number;
  handleCheckboxClick: (id: string) => void;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearSelectedList: () => void;
};

export const useSelectAll = (items: Item[]): UseSelectAll => {
  const [selectedList, setSelectedList] = useState<string[]>([]);

  const handleCheckboxClick = (id: string) => {
    if (selectedList.includes(id)) {
      setSelectedList((currentSelectedList) => [...currentSelectedList.filter((el) => el !== id)]);
    } else {
      setSelectedList((currentSelectedList) => [...currentSelectedList, id]);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedList = items.map((item) => item.id);

      setSelectedList(newSelectedList);
      return;
    }
    setSelectedList([]);
  };

  const clearSelectedList = () => {
    setSelectedList([]);
  };

  const isChecked = (id: string) => selectedList.includes(id);

  const selectedCount = selectedList.length;

  return {
    selectedList,
    isChecked,
    selectedCount,
    handleCheckboxClick,
    handleSelectAllClick,
    clearSelectedList,
  };
};
