import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Formik, Field, FastField } from "formik";
import { TextField } from "formik-material-ui";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import * as Yup from "yup";
import PasswordValidation from "../PasswordValidation";

import AuthLayout from "../../themes/layouts/Auth";
import useStyles from "./Styles";
import useButtonStyles from "../../styles/Buttons";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type Props = {
  onSubmit: (values: CreateUserVars, formikBag: unknown) => void | Promise<unknown>;
  accountCreated: boolean;
};

const validationSchema = Yup.object().shape({
  input: Yup.object().shape({
    email: Yup.string().email("Please enter a valid email").required("Email is required"),
    first_name: Yup.string()
      .min(2, "Too short!")
      .max(30, "Too long!")
      .required("First name is required"),
    last_name: Yup.string()
      .min(2, "Too short!")
      .max(30, "Too long!")
      .required("Last name is required"),
  }),
  registrationKey: Yup.string().required("Signup Key is required"),
});

const Signup: React.FC<Props> = ({ onSubmit, accountCreated }) => {
  const [showPassword, setShowPassword] = useState(false),
    [password, setPassword] = useState("");

  const { classes } = useStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();

  const handleShowPasswordClick = () => setShowPassword((prev) => !prev);

  return (
    <AuthLayout>
      <div>
        <Typography variant="h1">Create an account</Typography>

        <Typography variant="subtitle1">
          Already have an account?{" "}
          <Link component={NavLink} to="/user/login">
            Sign in
          </Link>
        </Typography>

        <Collapse in={accountCreated} mountOnEnter unmountOnExit>
          <Alert severity="success">
            <AlertTitle>Account created!</AlertTitle>
            Check your email box for confirmation link.
          </Alert>
          <Typography variant="subtitle1" style={{ paddingTop: 10 }}>
            Email has not arrived?{" "}
            <Link component={NavLink} to="/user/resend-activation">
              Resend Activation
            </Link>
          </Typography>
        </Collapse>

        <Collapse in={!accountCreated} mountOnEnter unmountOnExit>
          <Formik<CreateUserVars>
            validationSchema={validationSchema}
            initialValues={{
              input: {
                email: "",
                first_name: "",
                last_name: "",
                password: "",
              },
              registrationKey: "",
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit }) => (
              <>
                <div className={classes.inputBox}>
                  <FastField
                    component={TextField}
                    name="input.email"
                    label="Email address"
                    type="email"
                    fullWidth
                    variant="standard"
                    autoFocus
                  />
                </div>
                <div className={classes.inputBox}>
                  <Box display="flex" width="100%">
                    <Box pr={1} width="100%">
                      <FastField
                        component={TextField}
                        name="input.first_name"
                        label="First name"
                        fullWidth
                        variant="standard"
                      />
                    </Box>
                    <Box pl={1} width="100%">
                      <FastField
                        component={TextField}
                        name="input.last_name"
                        label="Last name"
                        fullWidth
                        variant="standard"
                      />
                    </Box>
                  </Box>
                </div>

                <div className={classes.inputBox}>
                  <Field
                    component={TextField}
                    name="input.password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    variant="standard"
                    onKeyUp={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onMouseDown={handleShowPasswordClick}
                            className={classes.showPasswordBtn}
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <PasswordValidation password={password} />
                </div>

                <div className={classes.inputBox}>
                  <FastField
                    component={TextField}
                    name="registrationKey"
                    label="Signup key"
                    fullWidth
                    variant="standard"
                  />
                </div>
                <Button
                  fullWidth
                  onClick={() => handleSubmit()}
                  className={cx(buttonClasses.blue, buttonClasses.large)}
                >
                  Create account
                </Button>
              </>
            )}
          </Formik>
        </Collapse>
      </div>
    </AuthLayout>
  );
};

export default Signup;
