import ResetPasswordForm from "../../components/Auth/ResetPasswordForm";
import { notify, notifySimple } from "../../themes/components/Notifications";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import { apiUrl } from "../../utils/Axios";

const UserResetPasswordRoute: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (values: ResetPasswordVars, { setSubmitting }: any) => {
    try {
      axios
        .post(apiUrl() + "forgotten-password", values)
        .then((response) => {
          const userPasswordReset = response.data.userPasswordReset;
          if (isEmpty(userPasswordReset.messages) && userPasswordReset.result) {
            notifySimple(["Please check your email for reset instructions to continue"], "info");
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            notify(userPasswordReset.messages, "error");
          }
        })
        .catch((err) => {
          const userPasswordReset = err.response.data.userPasswordReset;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          notify(userPasswordReset.messages, "error");
        });
    } catch (error) {
      setSubmitting(false);
      console.error(error);
    }
  };

  return <ResetPasswordForm onSubmit={handleSubmit} />;
};

export default UserResetPasswordRoute;
