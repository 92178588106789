import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { logout } from "../../api/User";
import { AccountContext } from "../../contexts/CurrentUser";
import { makeStyles } from "../../styles";
import useButtonStyles from "../../styles/Buttons";
import usePopoverStyles from "../../styles/Popovers";

import Logo from "../../images/logo.svg";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import MergeOutlinedIcon from "@mui/icons-material/MergeOutlined";

const useStyles = makeStyles()((theme) => ({
  root: {
    background: "#fff",
    borderBottom: "1px solid #ebeaed",
    position: "fixed",
    zIndex: 10,
    width: "100%",
    top: 0,
    left: 0,
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, isMobileOnly ? 2 : 4),
  },
  logo: {
    height: isMobileOnly ? 12 : 18,
    marginTop: 10,
    marginRight: theme.spacing(3),
  },
  mainMenu: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  userButton: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
    lineHeight: "1.2em",
    "& svg": {
      marginLeft: theme.spacing(1),
    },
  },
  dropdownMenu: {
    padding: theme.spacing(0.5),
  },
  dropdownMenuItem: {
    fontSize: 12,
    fontWeight: 500,

    "& svg": {
      width: 18,
      marginRight: theme.spacing(1.5),
      color: theme.palette.text.secondary,
    },
  },
  dropdownDivider: {
    margin: theme.spacing(0.5, 1),
  },
}));

const Header: React.FC = () => {
  const currentUser = useContext(AccountContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { classes } = useStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();
  const { classes: popoverClasses } = usePopoverStyles();

  const logUserOut = async () => {
    const logged_out = await logout();
    if (!logged_out) {
      return;
    }
    location.href = "/user/login";
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Box display="flex">
          <Link component={NavLink} to="/">
            <img src={Logo} alt="Obsurvant" className={classes.logo} />
          </Link>
          {currentUser.account && !isMobileOnly && (
            <List className={classes.mainMenu}>
              <ListItem button component={NavLink} to="/">
                Projects
              </ListItem>
              <ListItem button component={NavLink} to="/suppliers">
                Suppliers
              </ListItem>
            </List>
          )}
        </Box>

        {currentUser.account ? (
          <>
            <Button
              className={cx(buttonClasses.whiteOutlined, classes.userButton)}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <div>
                {currentUser.account.first_name} {currentUser.account.last_name}
              </div>
              <MenuIcon />
            </Button>

            <Popover
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              disableScrollLock
              PaperProps={{
                className: cx(popoverClasses.arrow, popoverClasses.arrowRight),
              }}
            >
              <List className={classes.dropdownMenu}>
                {isMobileOnly && (
                  <>
                    <ListItem
                      button
                      component={NavLink}
                      to="/"
                      onClick={() => setAnchorEl(null)}
                      className={classes.dropdownMenuItem}
                    >
                      <BallotOutlinedIcon />
                      Projects
                    </ListItem>
                    <ListItem
                      button
                      component={NavLink}
                      to="/suppliers"
                      onClick={() => setAnchorEl(null)}
                      className={classes.dropdownMenuItem}
                    >
                      <MergeOutlinedIcon />
                      Suppliers
                    </ListItem>
                  </>
                )}
                <ListItem
                  button
                  component={NavLink}
                  to="/user/edit"
                  onClick={() => setAnchorEl(null)}
                  className={classes.dropdownMenuItem}
                >
                  <PersonOutlineIcon />
                  Account
                </ListItem>

                <Divider className={classes.dropdownDivider} />

                <ListItem
                  button
                  onClick={() => void logUserOut()}
                  className={classes.dropdownMenuItem}
                >
                  <LogoutIcon />
                  Logout
                </ListItem>
              </List>
            </Popover>
          </>
        ) : (
          <Button component={NavLink} to="/" className={cx(buttonClasses.whiteOutlined)}>
            Sign in
          </Button>
        )}
      </div>
    </div>
  );
};

export default Header;
