export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  notation: "compact",
});

export const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 2,
  notation: "compact",
});

export const numberFormatterFull = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 0,
});
