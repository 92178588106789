import MainLayout from "./Main";
import ProfileSidebar from "../../components/Profile/Sidebar";
import { makeStyles } from "../../styles";

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "flex",
    position: "relative",
  },
  sidebar: {
    width: 250,
    position: "fixed",
  },
  mainContent: {
    flexGrow: 1,
    background: "#fff",
    borderRadius: 5,
    padding: theme.spacing(2),
    marginLeft: 270,
  },
}));

const ProfileLayout: React.FC = ({ children }) => {
  const { classes } = useStyles();

  return (
    <MainLayout>
      <div className={classes.wrapper}>
        <div className={classes.sidebar}>
          <ProfileSidebar />
        </div>
        <div className={classes.mainContent}>{children}</div>
      </div>
    </MainLayout>
  );
};

export default ProfileLayout;
