import { useTheme } from "@mui/material/styles";
import { createMakeStyles } from "tss-react";
import createCache from "@emotion/cache";

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

export const { makeStyles, useStyles } = createMakeStyles({ useTheme });
