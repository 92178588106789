import ProfileLayout from "../../themes/layouts/Profile";
import UsersList from "../../components/Users/List";

const UsersListRoute: React.FC = () => {
  return (
    <ProfileLayout>
      <UsersList />
    </ProfileLayout>
  );
};

export default UsersListRoute;
