import { NavLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import { makeStyles } from "../../styles";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SecurityIcon from "@mui/icons-material/Security";
//import GroupIcon from "@mui/icons-material/Group";
//import ListAltIcon from "@mui/icons-material/ListAlt";

const useStyles = makeStyles()((theme) => ({
  navButton: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: theme.spacing(0.5),
    borderRadius: 4,

    "& svg": {
      height: 24,
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },

    "&.active": {
      backgroundColor: "#EAE8FB",

      "& svg": {
        color: theme.palette.secondary.main,
      },
    },
  },
}));

const ProfileSidebar: React.FC = () => {
  const { classes } = useStyles();

  return (
    <>
      <Typography variant="h2">Account</Typography>

      <List>
        <ListItem button component={NavLink} to="/user/edit" className={classes.navButton}>
          <AccountBoxIcon />
          Profile
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to="/user/change-password"
          className={classes.navButton}
        >
          <SecurityIcon />
          Change Password
        </ListItem>
      </List>

      {/*
      <Typography variant="h2" style={{ paddingTop: 20 }}>
        Settings
      </Typography>
      <List>
        <ListItem button component={NavLink} to="/users" className={classes.navButton}>
          <GroupIcon />
          Users
        </ListItem>
      </List>
      */}
    </>
  );
};

export default ProfileSidebar;
