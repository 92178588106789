import { useContext } from "react";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import Login from "../../components/Auth/Login";
import { AccountContext } from "../../contexts/CurrentUser";
import { userToken } from "../../utils/Cookies";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { apiUrl } from "../../utils/Axios";

const UserLoginRoute: React.FC = () => {
  const { accountRefetch } = useContext(AccountContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (values: LoginVars, { setSubmitting }: any) => {
    try {
      axios
        .post(apiUrl() + "login", values)
        .then((response) => {
          const userLogin = response.data.userLogin;
          setSubmitting(false);
          if (isEmpty(userLogin.messages) && userLogin.result) {
            if (userToken.write(userLogin.result.token as string)) {
              notifySimple(["Logged in!"], "success");
              //void accountRefetch();
              location.href = "/";
            } else {
              notifySimple(["An unexpected error occurred"], "error");
            }
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            notifySimple([userLogin.messages], "error");
          }
        })
        .catch((error) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          notify(error.response.data.userLogin.messages, "error");
          setSubmitting(false);
        });
    } catch (error) {
      setSubmitting(false);
    }
  };

  return <Login onSubmit={handleSubmit} />;
};

export default UserLoginRoute;
