import { makeStyles } from "./index";

export default makeStyles()((theme) => ({
  close: {
    backgroundColor: "transparent",
    border: 0,
    right: 12,
    cursor: "pointer",
    padding: 0,
    position: "absolute",
    textAlign: "center",
    top: 12,
    "& svg": {
      height: 22,
      color: "#E3E1E5",
    },
    "&:hover svg": {
      color: "#777",
    },
  },
  content: {
    padding: theme.spacing(4, 6),
  },
  dialogActions: {
    paddingBottom: 24,
  },
}));
