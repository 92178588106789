import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import useModalStyles from "../../styles/Modals";
import useButtonStyles from "../../styles/Buttons";
import useSharedStyles from "../../styles/Shared";
import { getProjectSupplierFlaggedAnswers } from "../../api/Project";
import { notify, notifySimple } from "../../themes/components/Notifications";
import isEmpty from "lodash/isEmpty";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectSupplierId: string;
  baseName: string;
};

const AnswersModal: React.FC<Props> = ({ open, setOpen, projectSupplierId, baseName }) => {
  const { classes: modalClasses } = useModalStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();
  const { classes: sharedClasses } = useSharedStyles();
  const [answers, setAnswers] = useState<ProjectSupplierAnswer[]>([]),
    [searchPhrase, setSearchPhrase] = useState<string>("");

  const searchedAnswers = answers.filter((answer) => {
    if (isEmpty(searchPhrase) || answers.length === 0) {
      return true;
    } else {
      return (
        answer.title.indexOf(searchPhrase) > -1 ||
        answer.answer.indexOf(searchPhrase) > -1 ||
        searchPhrase.indexOf(answer.number.toString()) > -1
      );
    }
  });

  const getAnswers = (): void => {
    try {
      getProjectSupplierFlaggedAnswers(projectSupplierId)
        .then((response) => {
          const { result, messages } = response.ProjectSupplierFlaggedAnswers;
          if (!isEmpty(messages)) {
            notify(messages, "error");
          } else {
            if (result.length) {
              setAnswers(result);
            }
          }
        })
        .catch(() => {
          notifySimple(["Unexpected error occurred"], "error");
        });
    } catch (err) {
      notifySimple(["Unexpected error occurred"], "error");
    }
  };

  useEffect(() => {
    if (open) {
      void getAnswers();
    }
  }, [projectSupplierId, open]);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={() => setOpen(false)} keepMounted={false}>
      <IconButton className={modalClasses.close} onClick={() => setOpen(false)}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" mr={3}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            Answers
            <TextField
              sx={{ marginLeft: 4, width: 250 }}
              variant="standard"
              label="Search by Number, Title or Answer"
              value={searchPhrase}
              onChange={(e) => setSearchPhrase(e.target.value)}
            />
          </Box>
          <Button
            style={{ marginLeft: 10 }}
            className={cx(buttonClasses.blue, buttonClasses.iconButton)}
            onClick={() => {
              const table = document.querySelector("#AnswersTable");
              if (table) {
                const workbook = XLSX.utils.book_new();
                const opts: XLSX.Table2SheetOpts = { raw: false };
                const sheet = XLSX.utils.table_to_sheet(table, opts);
                XLSX.utils.book_append_sheet(workbook, sheet, `Responses`);
                XLSX.writeFile(workbook, `${baseName}_flagged_answers.xlsx`);
              }
            }}
          >
            <FileDownloadIcon /> XLSX
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent className={modalClasses.content}>
        <Table id="AnswersTable" className={sharedClasses.responsesContentTable}>
          <TableHead>
            <TableRow>
              <TableCell>Respondent Id</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Answer</TableCell>
              <TableCell>Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedAnswers.length > 0 ? (
              searchedAnswers.map((answer, i) => (
                <TableRow key={`answer${i}`}>
                  <TableCell>{answer.respondent_id}</TableCell>
                  <TableCell>{answer.number}</TableCell>
                  <TableCell>{answer.title}</TableCell>
                  <TableCell
                    style={{
                      minWidth: "100px",
                      maxWidth: "200px",
                      overflowWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    <em>{answer.answer}</em>
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: "100px",
                      maxWidth: "200px",
                      overflowWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    {answer.response_types}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>No records found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default AnswersModal;
