import { isMobileOnly } from "react-device-detect";

import { makeStyles } from "../../styles";
import AuthBg from "../../images/auth_bg.png";

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "flex",
    minHeight: "100vh",
    "& > *": {
      flexBasis: "100%",
      flexShrink: 1,
    },
  },
  leftBox: {
    backgroundImage: `url(${AuthBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    padding: theme.spacing(2),
  },
  rightBoxInner: {
    maxWidth: 395,
    width: "100%",
  },
}));

const AuthLayout: React.FC = ({ children }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      {!isMobileOnly && <div className={classes.leftBox}></div>}

      <div className={classes.rightBox}>
        <div className={classes.rightBoxInner}>{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
