import axios from "axios";
import { userToken } from "./Cookies";
import { ClientJS } from "clientjs";

const clientJS = new ClientJS();

const fingerprint = clientJS.getCustomFingerprint(
  clientJS.getCanvasPrint(),
  clientJS.getColorDepth(),
  clientJS.getFonts(),
  clientJS.getTimeZone()
);

const authHeaders = {
  headers: {
    "X-Obs-Client": userToken.read() ? "obsrv-test-respondent-1520" : `${fingerprint}`,
    Authorization: `Bearer ${userToken.read()}`,
  },
};

/**
 * Returns the API url
 * @returns string
 */
export const apiUrl = (): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return import.meta.env.VITE_APP_API_URL;
};

/**
 * Builds an axios get request with authorisation header
 * @param string uri
 * @returns Promise
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRequest = (uri: string): Promise<any> => {
  return axios.get(apiUrl() + uri, authHeaders);
};

/**
 * Builds an axios post request with authorisation header
 * @param string uri
 * @returns Promise
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postRequest = (uri: string, formData: unknown): Promise<any> => {
  return axios.post(apiUrl() + uri, formData, authHeaders);
};

/**
 * Builds an axios patch request with authorisation header
 * @param string uri
 * @returns Promise
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const patchRequest = (uri: string, formData: unknown): Promise<any> => {
  return axios.patch(apiUrl() + uri, formData, authHeaders);
};

/**
 * Builds an axios delete request with authorisation header
 * @param string uri
 * @returns Promise
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteRequest = (uri: string): Promise<any> => {
  return axios.delete(apiUrl() + uri, authHeaders);
};
