import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";

import ResponsesModal from "./ResponsesModal";
import AnswersModal from "./AnswersModal";
import Tooltip from "../../themes/components/Tooltip";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { numberFormatterFull } from "../../utils/Formatters";
import { getLocalTime } from "../../utils/Time";
import useSharedStyles from "../../styles/Shared";
import useButtonStyles from "../../styles/Buttons";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { updateProject } from "../../api/Project";

type Props = {
  project: Project;
  responses: ProjectResponse[];
  refetch: () => void;
};

const Project: React.FC<Props> = ({ project, responses, refetch }) => {
  const [urlsToShow, setUrlsToShow] = useState<string[]>([]);
  const [modalResponses, setModalResponses] = useState(responses);
  const [responsesModalBaseName, setResponsesModalBaseName] = useState(project.name);
  const [showResponsesModal, setShowResponsesModal] = useState<boolean>(false);
  const [showAnswersModal, setShowAnswersModal] = useState<boolean>(false);
  const [modalSupplierId, setModalSupplierId] = useState("");

  const { classes, cx } = useSharedStyles();
  const { classes: buttonClasses } = useButtonStyles();
  const updateSupplierResponses = (supplierId: string) => {
    setModalResponses(responses.filter((r) => r.project_supplier_id === supplierId));
  };

  useEffect(() => {
    if (modalSupplierId) {
      updateSupplierResponses(modalSupplierId);
    }
  }, [responses]);

  const onUpdateSubmit = async (values: ProjectUpdateVars) => {
    try {
      const response = await updateProject(values.id, values);
      const { messages, result } = response.projectUpdate;

      if (!isEmpty(messages)) {
        notify(messages);
      } else if (result) {
        notifySimple(["Project updated"], "success");
        refetch();
      }
    } catch (err) {
      notifySimple(["Error updating project"], "error");
    }
  };

  const statusTag = (status: string) => {
    switch (status) {
      case "running":
        return <div className={cx(classes.tag, classes.tagPositive)}>{status}</div>;
      case "pending":
        return <div className={cx(classes.tag, classes.tagNeutral)}>{status}</div>;
      case "closed":
        return <div className={cx(classes.tag, classes.tagNegative)}>{status}</div>;
      default:
        return <div className={cx(classes.tag)}>{status}</div>;
    }
  };

  const generateSurveyUrl = (project_supplier: ProjectSupplier) => {
    let survey_url = `${location.protocol}//${location.host}/response`;

    survey_url += survey_url.includes("?") ? "&" : "?";
    survey_url += `obsrv_pid=${project.id}&obsrv_sid=${project_supplier.id}&`;

    survey_url += compact(
      project_supplier.fields.map((field) => {
        if (field.source) {
          return `${field.source.name}=${field.source.value}`;
        } else if (field.name && field.value) {
          return `${field.name}=${field.value}`;
        } else {
          return "";
        }
      })
    ).join("&");

    return (
      <Alert severity="info">
        <AlertTitle>Survey url - to be placed in the Supplier</AlertTitle>
        {survey_url}
      </Alert>
    );
  };

  const generateSurveyRedirects = (project_supplier: ProjectSupplier) => {
    const respondent_key = project_supplier.respondent_key || "obsrv_id";
    const status_key = project_supplier.status_key || "obsrv_v";
    const baseUrl = `${location.protocol}//${location.host}/complete?${respondent_key}=${
      project_supplier.opening_tag || "{{"
    }${respondent_key}${project_supplier.closing_tag || "}}"}&${status_key}=`;

    // pass back only required fields?
    const supplierFields = compact(
      project_supplier.fields
        .filter((field) => field.required || field.source?.required)
        .map((field) => {
          if (field.source) {
            return `${field.source.name}=${project_supplier.opening_tag || "{{"}${
              field.source.name
            }${project_supplier.closing_tag || "}}"}`;
          } else if (field.name && field.name) {
            return `${field.name}=${project_supplier.opening_tag || "{{"}${field.name}${
              project_supplier.closing_tag || "}}"
            }`;
          } else {
            return "";
          }
        })
    ).join("&");

    return (
      <Alert severity="info">
        <AlertTitle>Survey redirect endpoints - to be placed in the Survey Tool</AlertTitle>
        <p>
          <strong>Complete</strong>:{" "}
          {`${baseUrl}${project.response_statuses.completed}&${supplierFields}`}
        </p>
        <p>
          <strong>Screened Out</strong>:{" "}
          {`${baseUrl}${project.response_statuses.screened_out}&${supplierFields}`}
        </p>
        <p>
          <strong>Quality Term</strong>:{" "}
          {`${baseUrl}${project.response_statuses.terminated}&${supplierFields}`}
        </p>
        <p>
          <strong>Quota Full</strong>:{" "}
          {`${baseUrl}${project.response_statuses.quota_full}&${supplierFields}`}
        </p>
      </Alert>
    );
  };

  const calculateSupplierLoi = (
    project_supplier_id: string,
    type: "completes" | "terminations" = "completes"
  ) => {
    const supplierResponses = responses.filter(
      (r) => r.project_supplier_id === project_supplier_id
    );
    const filteredResponses = supplierResponses.filter(
      (sr) =>
        sr.finished_at !== undefined &&
        (type === "terminations"
          ? ["terminated", "screened_out"].includes(sr.status)
          : sr.status === "completed")
    );

    if (!filteredResponses.length) {
      return 0;
    }

    const totalMs = filteredResponses
      .map((r) =>
        r.finished_at ? new Date(r.finished_at).getTime() - new Date(r.inserted_at).getTime() : 0
      )
      .reduce((acc, val) => acc + val, 0);
    return Math.round(totalMs / 1000 / 60 / filteredResponses.length);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box display="flex" alignItems="flex-start">
          <Typography variant="h1">{project.name}</Typography>
          {statusTag(project.status.toLowerCase())}
        </Box>

        <div>
          {project.status !== "RUNNING" && (
            <Tooltip title="Launch" placement="top">
              <IconButton
                onClick={() =>
                  void onUpdateSubmit({
                    id: project.id,
                    input: { status: "RUNNING" },
                  })
                }
              >
                <PlayCircleIcon />
              </IconButton>
            </Tooltip>
          )}
          {project.status === "RUNNING" && (
            <Tooltip title="Pause" placement="top">
              <IconButton
                onClick={() =>
                  void onUpdateSubmit({
                    id: project.id,
                    input: { status: "PENDING" },
                  })
                }
              >
                <PauseCircleIcon />
              </IconButton>
            </Tooltip>
          )}
          {project.status !== "CLOSED" && (
            <Tooltip title="Close" placement="top">
              <IconButton
                onClick={() =>
                  void onUpdateSubmit({
                    id: project.id,
                    input: { status: "CLOSED" },
                  })
                }
              >
                <StopCircleIcon />
              </IconButton>
            </Tooltip>
          )}
          <Button
            className={cx(buttonClasses.blue, buttonClasses.small)}
            style={{ marginRight: 6 }}
            onClick={() => {
              setModalResponses(responses);
              setResponsesModalBaseName(project.name);
              setShowResponsesModal(true);
            }}
          >
            Responses
          </Button>
          <Button
            component={NavLink}
            to={`/project/${project.id}/edit`}
            className={cx(
              buttonClasses.whiteOutlined,
              buttonClasses.small,
              buttonClasses.iconButton
            )}
          >
            Edit
          </Button>
        </div>
      </Box>

      <div className={classes.contentBox}>
        <Table className={cx(classes.contentTable, isMobileOnly && classes.mobileContentTable)}>
          <TableBody>
            {project.suppliers.map((supplier) => [
              <TableRow key={supplier.id}>
                <TableCell rowSpan={2} width={200}>
                  <div className={classes.itemName}>{supplier.source.name}</div>
                  {supplier.description ? (
                    <p style={{ margin: 0 }}>{supplier.description}</p>
                  ) : null}
                  <div className={classes.itemSubName}>
                    Last complete:{" "}
                    <i>
                      {supplier.last_complete_at
                        ? getLocalTime(supplier.last_complete_at).fromNow()
                        : "never"}
                    </i>
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.itemStats}>
                    <div className={classes.itemStatsElement}>
                      <div className={cx(classes.itemStatsNumber)}>
                        {numberFormatterFull.format(supplier.entrants)}
                      </div>
                      <div>Entrants</div>
                    </div>
                    <div className={classes.itemStatsElement}>
                      <div className={cx(classes.itemStatsNumber, "positive")}>
                        {numberFormatterFull.format(supplier.completed)}
                      </div>
                      <div>completed</div>
                    </div>
                    <div className={classes.itemStatsElement}>
                      <div className={cx(classes.itemStatsNumber, "negative")}>
                        {numberFormatterFull.format(supplier.screened_out)}
                      </div>
                      <div>Screened Out</div>
                    </div>
                    <div className={classes.itemStatsElement}>
                      <div className={cx(classes.itemStatsNumber, "negative")}>
                        {numberFormatterFull.format(supplier.terminated)}
                      </div>
                      <div>Quality Term</div>
                    </div>
                    <div className={classes.itemStatsElement}>
                      <div className={cx(classes.itemStatsNumber, "neutral")}>
                        {numberFormatterFull.format(supplier.quota_full)}
                      </div>
                      <div>Quota Full</div>
                    </div>
                  </div>
                  <div className={classes.itemStats}>
                    <div className={classes.itemStatsElement}>
                      <div className={cx(classes.itemStatsNumber)}>
                        {numberFormatterFull.format(calculateSupplierLoi(supplier.id))}
                        <span className={classes.itemStatsSub}>min</span>
                      </div>
                      <div>LOI</div>
                    </div>
                    <div className={classes.itemStatsElement}>
                      <div className={cx(classes.itemStatsNumber)}>
                        {numberFormatterFull.format(
                          calculateSupplierLoi(supplier.id, "terminations")
                        )}
                        <span className={classes.itemStatsSub}>min</span>
                      </div>
                      <div>Termination LOI</div>
                    </div>
                    <div className={classes.itemStatsElement}>
                      <div className={cx(classes.itemStatsNumber)}>
                        {Math.round(
                          (supplier.completed
                            ? Number(supplier.completed) /
                              (Number(supplier.completed) + Number(supplier.screened_out))
                            : 0) * 100
                        )}
                        <span className={classes.itemStatsSub}>%</span>
                      </div>
                      <div>IR</div>
                    </div>
                    <div className={classes.itemStatsElement}>
                      <div className={cx(classes.itemStatsNumber)}>
                        {Math.round(
                          (supplier.entrants
                            ? (supplier.entrants -
                                supplier.completed -
                                supplier.quota_full -
                                supplier.terminated -
                                supplier.screened_out) /
                              supplier.entrants
                            : 0) * 100
                        )}
                        <span className={classes.itemStatsSub}>%</span>
                      </div>
                      <div>Drop Off</div>
                    </div>
                    <div className={classes.itemStatsElement}>
                      <div className={cx(classes.itemStatsNumber)}>
                        {numberFormatterFull.format(supplier.limit - supplier.completed)}
                        <span className={classes.itemStatsSub}>
                          / {numberFormatterFull.format(supplier.limit)}
                        </span>
                      </div>
                      <div>Remaining</div>
                    </div>
                  </div>
                </TableCell>
              </TableRow>,
              <TableRow key={`${supplier.id}-survey-url`}>
                <TableCell>
                  <Box display="flex" mb={1}>
                    <Button
                      className={cx(buttonClasses.blue, buttonClasses.small)}
                      onClick={() =>
                        setUrlsToShow((prev) =>
                          prev.includes(supplier.id)
                            ? prev.filter((id) => id !== supplier.id)
                            : [...prev, supplier.id]
                        )
                      }
                    >
                      {urlsToShow.includes(supplier.id) ? "Hide URLs" : "Show URLs"}
                    </Button>
                    <Button
                      className={cx(buttonClasses.blue, buttonClasses.small)}
                      style={{ marginLeft: 6 }}
                      onClick={() => {
                        setModalSupplierId(supplier.id);
                        setModalResponses(
                          responses.filter((r) => r.project_supplier_id === supplier.id)
                        );
                        setResponsesModalBaseName(`${project.name}-${supplier.source.name}`);
                        setShowResponsesModal(true);
                      }}
                    >
                      Supplier Responses
                    </Button>
                    {supplier.pre_survey_tool && (
                      <Button
                        className={cx(buttonClasses.blue, buttonClasses.small)}
                        style={{ marginLeft: 6 }}
                        onClick={() => {
                          setModalSupplierId(supplier.id);
                          setResponsesModalBaseName(`${project.name}-${supplier.source.name}`);
                          setShowAnswersModal(true);
                        }}
                      >
                        Flagged Answers
                      </Button>
                    )}
                  </Box>
                  <Collapse in={urlsToShow.includes(supplier.id)}>
                    {generateSurveyUrl(supplier)}
                    {generateSurveyRedirects(supplier)}
                  </Collapse>
                </TableCell>
              </TableRow>,
            ])}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <div className={classes.itemName}>Total:</div>
              </TableCell>
              <TableCell>
                <div className={classes.itemStats}>
                  <div className={classes.itemStatsElement}>
                    <div className={cx(classes.itemStatsNumber, "total")}>
                      {numberFormatterFull.format(
                        project.suppliers
                          .map((supplier) => supplier.entrants)
                          .reduce((acc, val) => acc + val, 0)
                      )}
                    </div>
                    <div>Entrants</div>
                  </div>
                  <div className={classes.itemStatsElement}>
                    <div className={cx(classes.itemStatsNumber, "positive", "total")}>
                      {numberFormatterFull.format(
                        project.suppliers
                          .map((supplier) => supplier.completed)
                          .reduce((acc, val) => acc + val, 0)
                      )}
                    </div>
                    <div>completed</div>
                  </div>
                  <div className={classes.itemStatsElement}>
                    <div className={cx(classes.itemStatsNumber, "negative", "total")}>
                      {numberFormatterFull.format(
                        project.suppliers
                          .map((supplier) => supplier.screened_out)
                          .reduce((acc, val) => acc + val, 0)
                      )}
                    </div>
                    <div>Screened Out</div>
                  </div>
                  <div className={classes.itemStatsElement}>
                    <div className={cx(classes.itemStatsNumber, "negative", "total")}>
                      {numberFormatterFull.format(
                        project.suppliers
                          .map((supplier) => supplier.terminated)
                          .reduce((acc, val) => acc + val, 0)
                      )}
                    </div>
                    <div>Quality Term</div>
                  </div>
                  <div className={classes.itemStatsElement}>
                    <div className={cx(classes.itemStatsNumber, "neutral", "total")}>
                      {numberFormatterFull.format(
                        project.suppliers
                          .map((supplier) => supplier.quota_full)
                          .reduce((acc, val) => acc + val, 0)
                      )}
                    </div>
                    <div>Quota Full</div>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>

      <ResponsesModal
        open={showResponsesModal}
        setOpen={setShowResponsesModal}
        responses={modalResponses}
        baseName={responsesModalBaseName}
        refetch={refetch}
      />

      <AnswersModal
        projectSupplierId={modalSupplierId}
        open={showAnswersModal}
        baseName={responsesModalBaseName}
        setOpen={setShowAnswersModal}
      />
    </>
  );
};

export default Project;
