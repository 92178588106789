import Header from "../../components/Nav/Header";
import Footer from "../../components/Nav/Footer";
import { makeStyles } from "../../styles";
import { isMobileOnly } from "react-device-detect";

const useStyles = makeStyles()((theme) => ({
  contentWrapper: {
    padding: `90px ${theme.spacing(isMobileOnly ? 2 : 4)} 20px`,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    flexGrow: 1,
    maxWidth: 1400,
    width: "100%",
  },
}));

const MainLayout: React.FC = ({ children }) => {
  const { classes } = useStyles();

  return (
    <>
      <Header />
      <div className={classes.contentWrapper}>
        <div className={classes.content}>{children}</div>
        <Footer />
      </div>
    </>
  );
};

export default MainLayout;
