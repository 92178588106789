import MainLayout from "../../themes/layouts/Main";

const TermsConditions: React.FC = () => {
  return (
    <MainLayout>
      <div>
        <div>Terms and Conditions of Use Agreement</div>
        <div>
          <p>Content</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default TermsConditions;
