import { useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "@fontsource/montserrat";

export const COLORS = {
  main: "#504F54",
  secondary: "#4180FF",
  textMain: "#2E2C34",
  textSecondary: "#84818A",
  lightGrey: "#EBEAED",
  red: "#F45B54",
};

const MuiTheme: React.FC = ({ children }) => {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: COLORS.main,
          },
          secondary: {
            main: COLORS.secondary,
          },
          text: {
            primary: COLORS.textMain,
            secondary: COLORS.textSecondary,
          },
          action: {
            active: COLORS.secondary,
          },
          background: {
            default: "#f9f9f8",
          },
        },
        typography: {
          fontFamily: '"Montserrat", sans-serif',
          htmlFontSize: 16,
          h1: {
            fontSize: 32,
            fontWeight: 600,
            paddingBottom: 12,
          },
          h2: {
            fontSize: 26,
            fontWeight: 600,
            paddingBottom: 16,
          },
          h3: {
            fontSize: 18,
            fontWeight: 600,
            paddingBottom: 24,
          },
          h4: {
            fontSize: 16,
            fontWeight: 600,
            paddingBottom: 12,
          },
          subtitle1: {
            fontSize: 14,
            paddingBottom: 24,
            color: COLORS.textSecondary,
          },
        },
        components: {
          MuiTextField: {
            styleOverrides: {
              root: {
                "input::-webkit-clear-button, input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                  {
                    display: "none",
                    margin: 80,
                  },
                "input[type=number]": {
                  MozAppearance: "textfield",
                },
              },
            },
            variants: [
              {
                props: { variant: "standard" },
                style: {
                  "& .MuiInputBase-input": {
                    color: COLORS.main, // Text color
                  },
                  "& .MuiFormLabel-root": {
                    textOverflow: "ellipsis !important",
                    color: COLORS.textSecondary,
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: COLORS.lightGrey, // Underline
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: COLORS.secondary, // Underline on hover (not working)
                    borderBottomWidth: 1,
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: COLORS.secondary, // Underline on focus
                    borderBottomWidth: 1,
                  },
                },
              },
              {
                props: { variant: "outlined" },
                style: {
                  "& .MuiInputBase-input": {
                    fontSize: 14,
                    paddingTop: 8,
                    paddingBottom: 8,
                    background: "#fff",
                  },
                },
              },
            ],
          },
          MuiFormControlLabel: {
            styleOverrides: {
              root: {
                color: COLORS.textSecondary,
                "& .MuiFormControlLabel-label": {
                  fontSize: 14,
                },
              },
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                color: COLORS.secondary,
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: "none",
                fontWeight: 600,
                borderRadius: 0,
              },
            },
          },
          MuiAutocomplete: {
            styleOverrides: {
              root: {
                "& .MuiOutlinedInput-root": {
                  paddingTop: 0,
                  paddingBottom: 0,
                  background: "#fff",
                },
              },
            },
          },
        },
      }),
    []
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiTheme;
