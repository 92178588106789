import isEmpty from "lodash/isEmpty";
import ChangePassword from "../../components/Profile/ChangePassword";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { updatePassword } from "../../api/User";
import zxcvbn from "zxcvbn";

const UserChangePasswordRoute: React.FC = () => {
  const onSubmit = async (values: UserPasswordChangeVars) => {
    try {
      const passwordScore = zxcvbn(values.password);
      if (passwordScore.score < 4) {
        notifySimple(["The password must be rated strong!"], "error");
        return;
      }
      const response = await updatePassword(values);
      const { result, messages } = response.userPasswordChange;

      if (!isEmpty(messages)) {
        notify(messages);
      } else if (result) {
        notifySimple(["Password updated"], "success");
      }
    } catch (err) {
      notifySimple(["Error updating password"], "error");
    }
  };

  return <ChangePassword onSubmit={onSubmit} />;
};

export default UserChangePasswordRoute;
