import Activate from "./routes/Users/Activate";
import Signup from "./routes/Users/Signup";
import Login from "./routes/Users/Login";
import ResetPassword from "./routes/Users/ResetPassword";
import ResetPasswordNew from "./routes/Users/ResetPasswordSetNew";
import ResendActivation from "./routes/Users/ResendActivation";
import EditUser from "./routes/Users/Edit";
import ChangePassword from "./routes/Users/ChangePassword";
import TermsConditions from "./components/Pages/TermsConditions";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import ProjectsList from "./routes/Projects/List";
import Project from "./routes/Projects/Single";
import ProjectCreate from "./routes/Projects/Create";
import ProjectEdit from "./routes/Projects/Edit";
import SuppliersList from "./routes/Suppliers/List";
import SupplierCreate from "./routes/Suppliers/Create";
import SupplierEdit from "./routes/Suppliers/Edit";
import UsersList from "./routes/Users/List";
import DeviceForensiq from "./routes/Responses/DeviceForensiq";
import ResponsePrepare from "./routes/Responses/Prepare";
import ResponseFinalize from "./routes/Responses/Finalize";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/user/activate/:code"
          element={
            <ProtectedRoute guest>
              <Activate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/login"
          element={
            <ProtectedRoute guest>
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/signup"
          element={
            <ProtectedRoute guest>
              <Signup />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/reset-password"
          element={
            <ProtectedRoute guest>
              <ResetPassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/resend-activation"
          element={
            <ProtectedRoute guest>
              <ResendActivation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/reset-password/:token"
          element={
            <ProtectedRoute guest>
              <ResetPasswordNew />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/edit"
          element={
            <ProtectedRoute>
              <EditUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/change-password"
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <UsersList />
            </ProtectedRoute>
          }
        />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/suppliers"
          element={
            <ProtectedRoute>
              <SuppliersList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/supplier/create"
          element={
            <ProtectedRoute>
              <SupplierCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/supplier/:id/edit"
          element={
            <ProtectedRoute>
              <SupplierEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/project/create"
          element={
            <ProtectedRoute>
              <ProjectCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/project/:id/edit"
          element={
            <ProtectedRoute>
              <ProjectEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/project/:id/view"
          element={
            <ProtectedRoute>
              <Project />
            </ProtectedRoute>
          }
        />
        <Route
          path="/respondent/:id/device-forensiq"
          element={
            <ProtectedRoute>
              <DeviceForensiq />
            </ProtectedRoute>
          }
        />
        <Route path="/response" element={<ResponsePrepare />} />
        <Route path="/complete" element={<ResponseFinalize />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <ProjectsList />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
