import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

const CustomTooltip = styled(Tooltip)<TooltipProps>(({ theme }) => ({
  popper: {
    zIndex: 9500,
  },
  tooltip: {
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
  },
  arrow: {
    color: theme.palette.secondary.main,
  },
}));

export default CustomTooltip;
