import { TextField } from "formik-material-ui";
import useSharedStyles from "../../../styles/Shared";
import useButtonStyles from "../../../styles/Buttons";
import { Field, Formik, Form as FormikForm } from "formik";
import Button from "@mui/material/Button";

type Props = {
  questionData?: ProjectSupplierQuestion | undefined;
  newQuestionNumber?: number | undefined;
  handleSubmit: (question: ProjectSupplierQuestion) => void;
};

const Form: React.FC<Props> = ({ questionData, newQuestionNumber, handleSubmit }) => {
  const { classes } = useSharedStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();

  const initialQuestion =
    questionData !== undefined
      ? questionData
      : {
          title: "",
          number: newQuestionNumber !== undefined ? newQuestionNumber : 0,
          id: window.crypto.randomUUID(),
          deleted: false,
        };

  return (
    <Formik initialValues={initialQuestion} onSubmit={(values) => handleSubmit(values)}>
      {({ handleChange, values }) => (
        <FormikForm>
          <div className={classes.inputBox}>
            <Field
              component={TextField}
              name="title"
              label="Title"
              variant="standard"
              value={values.title}
              onChange={handleChange}
              fullWidth
            />
          </div>
          <div className={classes.inputBox}>
            <Field
              component={TextField}
              name="number"
              label="Number"
              variant="standard"
              value={values.number}
              onChange={handleChange}
              fullWidth
            />
          </div>
          <Button type="submit" className={cx(buttonClasses.largeSecondary, buttonClasses.blue)}>
            {questionData ? `Update Question ${questionData.number}` : "Add Question"}
          </Button>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
