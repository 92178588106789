import { useContext } from "react";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import { apiUrl } from "../../utils/Axios";

import ResetPasswordSetNewForm from "../../components/Auth/ResetPasswordSetNewForm";
import { AccountContext } from "../../contexts/CurrentUser";
import { userToken } from "../../utils/Cookies";
import { notify, notifySimple } from "../../themes/components/Notifications";
import zxcvbn from "zxcvbn";

const UserResetPasswordRoute: React.FC = () => {
  const { accountRefetch } = useContext(AccountContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (values: ResetPasswordSetNewVars, { setSubmitting }: any) => {
    try {
      const passwordScore = zxcvbn(values.password);
      if (passwordScore.score < 4) {
        notifySimple(["The password must be rated strong!"], "error");
        return;
      }
      axios
        .post(apiUrl() + "reset-password", values)
        .then((response) => {
          const userPasswordReset = response.data.userPasswordReset;
          if (isEmpty(userPasswordReset.messages) && userPasswordReset.result) {
            notifySimple(["Password changed"], "success");
            if (userPasswordReset?.result.token) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              userToken.write(userPasswordReset.result.token);
              notifySimple(["Logged in!"], "success");
              void accountRefetch();
            }
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            notify(userPasswordReset.messages, "error");
          }
        })
        .catch((err) => {
          const userPasswordReset = err.response.data.userPasswordReset;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          notify(userPasswordReset.messages, "error");
        });
    } catch (error) {
      setSubmitting(false);
      console.error(error);
    }
  };

  return <ResetPasswordSetNewForm onSubmit={handleSubmit} />;
};

export default UserResetPasswordRoute;
