import Select, { SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";

const ModelFormSelect = styled(Select)<SelectProps>(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontSize: 14,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default ModelFormSelect;
