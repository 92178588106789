import { KeyboardEvent } from "react";
import { NavLink } from "react-router-dom";
import { Formik, Form, FastField } from "formik";
import { TextField } from "formik-material-ui";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as Yup from "yup";

import AuthLayout from "../../themes/layouts/Auth";
import useStyles from "./Styles";
import useButtonStyles from "../../styles/Buttons";

type Props = {
  onSubmit: (values: ResetPasswordVars, formikBag: unknown) => void | Promise<unknown>;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email").required("Email is required"),
});

const ResetPasswordForm: React.FC<Props> = ({ onSubmit }) => {
  const { classes } = useStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();

  return (
    <AuthLayout>
      <Typography variant="h1">Reset password</Typography>
      <Typography variant="subtitle1">
        Back to{" "}
        <Link component={NavLink} to="/user/login">
          Sign in
        </Link>
      </Typography>
      <Formik<ResetPasswordVars>
        validationSchema={validationSchema}
        initialValues={{ email: "" }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Form>
            <div className={classes.inputBox}>
              <FastField
                component={TextField}
                label="Email"
                fullWidth
                variant="standard"
                type="email"
                name="email"
                onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                autoFocus
              />
            </div>
            <Box display="flex" justifyContent="flex-end" pt={2} className={classes.formLinks}>
              <Button type="submit" className={cx(buttonClasses.blue, buttonClasses.large)}>
                Reset
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default ResetPasswordForm;
