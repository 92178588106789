import zxcvbn from "zxcvbn";

type Props = {
  password: string;
};

const styles = {
  fontSize: "12px",
};

const scores = {
  0: "<span style='font-weight:bold;color:red;'>Very Weak</span>",
  1: "<span style='font-weight:bold;color:red;'>Very Weak</span>",
  2: "<span style='font-weight:bold;color:orange;'>Weak</span>",
  3: "<span style='font-weight:bold;color:grey;'>Good</span>",
  4: "<span style='font-weight:bold;color:green;'>Strong</span>",
};

const PasswordValidation: React.FC<Props> = ({ password }) => {
  const getScore = () => {
    if (password === "") {
      return "<span style='font-weight:bold;color:red;'>Very Weak</span>";
    }

    const result = zxcvbn(password);
    return scores[result.score];
  };

  return (
    <span style={styles}>
      Password Strength: <span dangerouslySetInnerHTML={{ __html: getScore() }} />
    </span>
  );
};

export default PasswordValidation;
