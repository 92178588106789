import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import MainLayout from "../../themes/layouts/Main";
import Project from "../../components/Projects/Single";
import Loader from "../../themes/components/Loader";
import { fetchProject } from "../../api/Project";

const ProjectRoute: React.FC = () => {
  const { id } = useParams<{ id: Project["id"] }>();
  const [data, setData] = useState<ProjectData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    const result = await fetchProject(id);
    setLoading(result.loading);
    setError(result.error);
    setData(result.data);
  };

  useEffect(() => {
    void fetchData();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  if (error || !data) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>
    );
  }

  if (data.project) {
    return (
      <MainLayout>
        <Project
          project={data.project}
          responses={data.project_responses}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          refetch={fetchData}
        />
      </MainLayout>
    );
  }

  return <Loader />;
};

export default ProjectRoute;
