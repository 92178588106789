import { notifySimple } from "../../../themes/components/Notifications";
import Form from "./Form";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

type Props = {
  questions: ProjectSupplierQuestion[];
  name: string;
  updateQuestions: (fieldName: string, questions: ProjectSupplierQuestion[]) => void;
};

const SupplierQuestions: React.FC<Props> = ({ questions, name, updateQuestions }) => {
  const validateQuestion = (question: ProjectSupplierQuestion): boolean => {
    if (!question.title || question.title.length < 3 || question.title.length > 255) {
      notifySimple(["The question title must be between 3 and 255 characters"], "error");
      return false;
    }

    if (!question.number) {
      notifySimple(["The question number is required"], "error");
      return false;
    }

    const doesQuestionExist = questions.find(function (obj) {
      return obj.number == question.number;
    });

    if (
      doesQuestionExist !== undefined &&
      doesQuestionExist.id !== question.id &&
      doesQuestionExist.deleted === false
    ) {
      notifySimple([`Question number ${question.number} already exists`], "error");
      return false;
    }

    return true;
  };

  const addQuestionToArray = (question: ProjectSupplierQuestion): void => {
    if (!validateQuestion(question)) {
      return;
    }

    const questionsCopy: ProjectSupplierQuestion[] = questions.length ? [...questions] : [];

    questionsCopy.push(question);
    updateQuestions(name, questionsCopy);
    notifySimple([`Question ${question.number} added`], "success");
  };

  const updateQuestionArray = (question: ProjectSupplierQuestion): void => {
    if (!validateQuestion(question)) {
      return;
    }

    let questionsCopy: ProjectSupplierQuestion[] = [...questions];

    // Delete the existing copy of the question
    questionsCopy = questionsCopy.filter(function (obj) {
      return obj.id !== question.id;
    });

    // Add it back with updated data
    questionsCopy.push(question);
    updateQuestions(name, questionsCopy);
    notifySimple([`Question ${question.number} updated`], "success");
  };

  const deleteQuestion = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const questionId = e.currentTarget.dataset.id;

    if (!questionId) {
      notifySimple([`No question id detected`], "error");
      return;
    }

    let questionsCopy: ProjectSupplierQuestion[] = [...questions];

    // Get a copy of the question
    const question = questionsCopy.find(function (obj) {
      return obj.id === questionId;
    });

    if (!question) {
      notifySimple([`No question found for that id`], "error");
      return;
    }

    // Set to deleted
    question.deleted = true;

    // Delete the existing copy of the question
    questionsCopy = questionsCopy.filter(function (obj) {
      return obj.id !== question.id;
    });

    // Add it back with updated data
    questionsCopy.push(question);
    updateQuestions(name, questionsCopy);
    notifySimple([`Question ${question.number} deleted`], "success");
  };

  const sortQuestions = () => {
    return questions.sort((a, b) => a.number - b.number);
  };

  return (
    <div>
      <Typography variant="subtitle1">
        The questions below will be used to evaluate the respondent before they enter the survey.
      </Typography>
      <h6>Existing Questions</h6>
      {questions.length ? (
        sortQuestions().map((question) =>
          question.deleted === false ? (
            <div key={`question-${Math.random()}`} style={{ position: "relative" }}>
              <div style={{ position: "absolute", top: "0", right: "0", zIndex: "5" }}>
                <IconButton onClick={deleteQuestion} data-id={question.id}>
                  <DeleteOutlineIcon style={{ pointerEvents: "none" }} />
                </IconButton>
              </div>
              <Form handleSubmit={updateQuestionArray} questionData={question} />
              <hr style={{ margin: "1.5rem 0" }} />
            </div>
          ) : null
        )
      ) : (
        <Typography variant="subtitle1">Add your first question below.</Typography>
      )}
      <h6>New Question</h6>
      <Form
        key={questions.length}
        handleSubmit={addQuestionToArray}
        newQuestionNumber={questions.length + 1}
      />
    </div>
  );
};

export default SupplierQuestions;
