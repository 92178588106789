import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Pagination from "@mui/material/Pagination";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import orderBy from "lodash/orderBy";

import ConfirmationDialog from "../../themes/components/ConfirmationDialog";
import Tooltip from "../../themes/components/Tooltip";
import { numberFormatter, numberFormatterFull } from "../../utils/Formatters";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { getLocalTime, dateFormat } from "../../utils/Time";
import { usePagination } from "../../hooks/usePagination";
import useSharedStyles from "../../styles/Shared";
import useButtonStyles from "../../styles/Buttons";
import usePopoverStyles from "../../styles/Popovers";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import SettingsIcon from "@mui/icons-material/Settings";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { updateProject, deleteProject } from "../../api/Project";

type Props = {
  projects: Project[];
  refetch: () => void;
};

interface ProjectStats extends Project {
  entrants: number;
  completed: number;
  screened_out: number;
  terminated: number;
  quota_full: number;
  incidence: number;
  conversion: number;
  loi: number;
  dropOff: number;
  quota: number;
  lastComplete: string;
}

const ProjectsList: React.FC<Props> = ({ projects, refetch }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [itemEdit, setItemEdit] = useState<Project | null>(null);
  const [itemDelete, setItemDelete] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState("inserted_at");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [searchPhrase, setSearchPhrase] = useState("");
  const [statusFilter, setStatusFilter] = useState(["pending", "running", "closed"]);

  const { classes } = useSharedStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();
  const { classes: popoverClasses } = usePopoverStyles();

  const perPage = 10;

  const { currentPage, pagesCount, offset, onPageChange } = usePagination(
    projects.length,
    perPage,
    true
  );

  const projectsStats: ProjectStats[] = projects.map((project) => {
    const entrants = project.suppliers
      .map((supplier) => supplier.entrants)
      .reduce((acc, val) => acc + val, 0);
    const completed = project.suppliers
      .map((supplier) => supplier.completed)
      .reduce((acc, val) => acc + val, 0);
    const screened_out = project.suppliers
      .map((supplier) => supplier.screened_out)
      .reduce((acc, val) => acc + val, 0);
    const terminated = project.suppliers
      .map((supplier) => supplier.terminated)
      .reduce((acc, val) => acc + val, 0);
    const quota_full = project.suppliers
      .map((supplier) => supplier.quota_full)
      .reduce((acc, val) => acc + val, 0);
    const incidence = (completed ? completed / (completed + screened_out) : 0) * 100;
    const conversion = (completed ? completed / entrants : 0) * 100;
    const dropOff =
      (entrants ? (entrants - completed - quota_full - terminated - screened_out) / entrants : 0) *
      100;
    const quota = project.suppliers
      .map((supplier) => supplier.limit)
      .reduce((acc, val) => acc + val, 0);
    const lastCompleteDate = compact(
      project.suppliers.map((supplier) => supplier.last_complete_at)
    ).reduce((acc, val) => (val > acc ? val : acc), "");

    const lastComplete = lastCompleteDate ? getLocalTime(lastCompleteDate).fromNow() : "never";

    return {
      ...project,
      entrants,
      completed,
      screened_out,
      terminated,
      quota_full,
      incidence,
      conversion,
      loi: 1,
      dropOff,
      quota,
      lastComplete,
    };
  });

  const onUpdateSubmit = async (values: ProjectUpdateVars) => {
    try {
      const response = await updateProject(values.id, values);
      const { messages, result } = response.projectUpdate;

      if (!isEmpty(messages)) {
        notify(messages);
      } else if (result) {
        notifySimple(["Project updated"], "success");
        refetch();
      }
    } catch (err) {
      notifySimple(["Error updating project"], "error");
    }
  };

  const onDeleteSubmit = async (values: ProjectDeleteVars) => {
    try {
      const response = await deleteProject(values.id);
      const { messages, result } = response.projectDelete;

      if (!isEmpty(messages)) {
        notify(messages);
      } else if (result) {
        notifySimple(["Project deleted"], "success");
        refetch();
      }
    } catch (err) {
      notifySimple(["Error deleting project"], "error");
    }
  };

  const statusTag = (status: string) => {
    switch (status) {
      case "running":
        return <div className={cx(classes.tag, classes.tagPositive)}>{status}</div>;
      case "pending":
        return <div className={cx(classes.tag, classes.tagNeutral)}>{status}</div>;
      case "closed":
        return <div className={cx(classes.tag, classes.tagNegative)}>{status}</div>;
      default:
        return <div className={cx(classes.tag)}>{status}</div>;
    }
  };

  const updateSearchPhrase = (newSearchPhrase: string) => {
    onPageChange(1);
    setSearchPhrase(newSearchPhrase);
  };

  const filteredProjectsStats = orderBy(
    projectsStats.filter(
      (ps) =>
        statusFilter.includes(ps.status.toLowerCase()) &&
        ps.name.toLowerCase().indexOf(searchPhrase.toLowerCase()) !== -1
    ),
    [sortBy === "name" ? (el) => el.name.toLowerCase().trim() : sortBy],
    [sortOrder]
  );

  const generateSettingsCell = (project: Project) => (
    <TableCell align="right" className="settings-cell">
      {project.status !== "RUNNING" && (
        <Tooltip title="Launch" placement="top">
          <IconButton
            onClick={() =>
              void onUpdateSubmit({
                id: project.id,
                input: { status: "RUNNING" },
              })
            }
          >
            <PlayCircleIcon />
          </IconButton>
        </Tooltip>
      )}
      {project.status === "RUNNING" && (
        <Tooltip title="Pause" placement="top">
          <IconButton
            onClick={() =>
              void onUpdateSubmit({
                id: project.id,
                input: { status: "PENDING" },
              })
            }
          >
            <PauseCircleIcon />
          </IconButton>
        </Tooltip>
      )}
      {project.status !== "CLOSED" && (
        <Tooltip title="Close" placement="top">
          <IconButton
            onClick={() =>
              void onUpdateSubmit({
                id: project.id,
                input: { status: "CLOSED" },
              })
            }
          >
            <StopCircleIcon />
          </IconButton>
        </Tooltip>
      )}

      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setItemEdit(project);
        }}
        className={classes.settingsButton}
      >
        <SettingsIcon />
      </IconButton>
    </TableCell>
  );

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        pb={2}
        flexDirection={isMobileOnly ? "column" : "row"}
      >
        <Box
          display="flex"
          width={isMobileOnly ? "100%" : "auto"}
          alignItems={isMobileOnly ? "center" : "flex-end"}
          flexDirection={isMobileOnly ? "column" : "row"}
        >
          <Typography variant="h1" sx={{ paddingBottom: 1, marginRight: 4 }}>
            Projects
          </Typography>
          <Autocomplete
            sx={{ width: isMobileOnly ? "100%" : 300, paddingRight: 2, paddingBottom: 1 }}
            freeSolo
            autoSelect={false}
            options={orderBy(projectsStats, [(el) => el.name.toLowerCase().trim()], ["asc"]).map(
              (project) => project.name
            )}
            value={searchPhrase}
            onChange={(_, v) => {
              updateSearchPhrase(v || "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Projects"
                variant="standard"
                autoFocus
                onChange={(e) => updateSearchPhrase(e.target.value)}
              />
            )}
          />
          <FormControl
            variant="standard"
            sx={{ width: isMobileOnly ? "100%" : 150, paddingRight: 2, paddingBottom: 1 }}
          >
            <InputLabel>Sort By</InputLabel>
            <Select
              fullWidth
              margin="dense"
              variant="standard"
              value={sortBy}
              onChange={(e) => {
                setSortBy(e.target.value);
              }}
            >
              <MenuItem value="inserted_at">Created Date</MenuItem>
              <MenuItem value="name">Project Name</MenuItem>
              <MenuItem value="status">Status</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ width: isMobileOnly ? "100%" : "auto", paddingRight: 2, paddingBottom: 1 }}
          >
            <InputLabel>Sort Order</InputLabel>
            <Select
              fullWidth
              margin="dense"
              variant="standard"
              value={sortOrder}
              onChange={(e) => {
                setSortOrder(e.target.value as "asc" | "desc");
              }}
            >
              <MenuItem value="desc">Descending</MenuItem>
              <MenuItem value="asc">Ascending</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ width: isMobileOnly ? "100%" : 200, paddingRight: 2, paddingBottom: 1 }}
          >
            <InputLabel>Status Filter</InputLabel>
            <Select
              fullWidth
              multiple
              margin="dense"
              variant="standard"
              value={statusFilter}
              onChange={(e: SelectChangeEvent<string[]>) => {
                setStatusFilter(
                  typeof e.target.value === "string" ? e.target.value.split(", ") : e.target.value
                );
              }}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="pending">
                <Checkbox checked={statusFilter.indexOf("pending") > -1} />
                <ListItemText>Pending</ListItemText>
              </MenuItem>
              <MenuItem value="running">
                <Checkbox checked={statusFilter.indexOf("running") > -1} />
                <ListItemText>Running</ListItemText>
              </MenuItem>
              <MenuItem value="closed">
                <Checkbox checked={statusFilter.indexOf("closed") > -1} />
                <ListItemText>Closed</ListItemText>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Button
          className={cx(buttonClasses.blue, buttonClasses.iconButton)}
          component={NavLink}
          to="/project/create"
        >
          <ControlPointIcon /> Add a Project
        </Button>
      </Box>

      {isEmpty(projects) ? (
        <Alert severity="info">
          <AlertTitle>No available Projects</AlertTitle>
          Use the button on the right to add a new Project
        </Alert>
      ) : (
        <div className={classes.contentBox}>
          <Table className={cx(classes.contentTable, isMobileOnly && classes.mobileContentTable)}>
            <TableBody>
              {filteredProjectsStats.slice(offset, offset + perPage).map((project) => (
                <TableRow key={project.id}>
                  {isMobileOnly && generateSettingsCell(project)}
                  <TableCell className="name-cell">
                    <div className={classes.itemWrapper}>
                      <div>
                        <div>
                          <NavLink
                            className={cx(classes.itemName, "link")}
                            to={`/project/${project.id}/view`}
                          >
                            {project.name}
                          </NavLink>
                          {statusTag(project.status.toLowerCase())}
                        </div>
                        <div className={classes.itemSubName}>
                          Created: <i>{getLocalTime(project.inserted_at).format(dateFormat)}</i>
                          <br />
                          by:{" "}
                          <i>
                            {project.account.first_name} {project.account.last_name}
                          </i>
                          <br />
                          Suppliers #: <i>{project.suppliers.length}</i>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.itemStats}>
                      <div className={classes.itemStatsElement}>
                        <div className={cx(classes.itemStatsNumber)}>
                          {numberFormatter.format(project.entrants)}
                        </div>
                        <div>Entrants</div>
                      </div>
                      <div className={classes.itemStatsElement}>
                        <div className={cx(classes.itemStatsNumber, "positive")}>
                          {numberFormatter.format(project.completed)}
                        </div>
                        <div>completed</div>
                      </div>
                      <div className={classes.itemStatsElement}>
                        <div className={cx(classes.itemStatsNumber, "negative")}>
                          {numberFormatter.format(project.screened_out)}
                        </div>
                        <div>Screened Out</div>
                      </div>
                      <div className={classes.itemStatsElement}>
                        <div className={cx(classes.itemStatsNumber, "negative")}>
                          {numberFormatter.format(project.terminated)}
                        </div>
                        <div>Quality Term</div>
                      </div>
                      <div className={classes.itemStatsElement}>
                        <div className={cx(classes.itemStatsNumber, "neutral")}>
                          {numberFormatter.format(project.quota_full)}
                        </div>
                        <div>Quota Full</div>
                      </div>
                    </div>
                    <div className={classes.itemStats}>
                      <div className={classes.itemStatsElement}>
                        <div className={cx(classes.itemStatsNumber)}>
                          {Math.round(project.incidence)}
                          <span className={classes.itemStatsSub}>%</span>
                        </div>
                        <div>IR</div>
                      </div>
                      <div className={classes.itemStatsElement}>
                        <div className={cx(classes.itemStatsNumber)}>
                          {Math.round(project.conversion)}
                          <span className={classes.itemStatsSub}>%</span>
                        </div>
                        <div>Conversion</div>
                      </div>
                      <div className={classes.itemStatsElement}>
                        <div className={cx(classes.itemStatsNumber)}>
                          <span className={cx(classes.itemStatsSub, "static")}>
                            {project.lastComplete}
                          </span>
                        </div>
                        <div>Last Completed</div>
                      </div>
                      <div className={classes.itemStatsElement}>
                        <div className={cx(classes.itemStatsNumber)}>
                          {Math.round(project.dropOff)}
                          <span className={classes.itemStatsSub}>%</span>
                        </div>
                        <div>Drop Off</div>
                      </div>
                      <div className={classes.itemStatsElement}>
                        <div className={cx(classes.itemStatsNumber)} style={{}}>
                          {numberFormatterFull.format(project.quota - project.completed)}
                          <span className={classes.itemStatsSub}>
                            / {numberFormatterFull.format(project.quota)}
                          </span>
                        </div>
                        <div>Remaining</div>
                      </div>
                    </div>
                  </TableCell>
                  {!isMobileOnly && generateSettingsCell(project)}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {projects.length > perPage && (
            <Pagination
              className={classes.pagination}
              size="small"
              count={pagesCount}
              page={currentPage}
              onChange={(event, page) => {
                if (page === currentPage) {
                  return;
                }

                onPageChange(page);
              }}
            />
          )}
        </div>
      )}

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl && itemEdit)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        disableScrollLock
        PaperProps={{
          className: cx(popoverClasses.arrow, popoverClasses.arrowRight),
        }}
      >
        <List className={classes.dropdownMenu}>
          <ListItem
            button
            onClick={() => {
              setAnchorEl(null);
              navigate(`/project/${itemEdit?.id}/view`);
            }}
            className={classes.dropdownMenuItem}
          >
            Details
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setAnchorEl(null);
              navigate(`/project/${itemEdit?.id}/edit`);
            }}
            className={classes.dropdownMenuItem}
          >
            Edit
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setAnchorEl(null);
              setItemDelete(itemEdit?.id || null);
            }}
            className={cx(classes.dropdownMenuItem, "red")}
          >
            Delete
          </ListItem>
        </List>
      </Popover>

      <ConfirmationDialog
        show={Boolean(itemDelete)}
        title="Are you sure?"
        description="This project will be permanently deleted"
        okButton="Confirm"
        cancelButton="Cancel"
        okAction={() => {
          if (itemDelete) {
            void onDeleteSubmit({ id: itemDelete });
          }
        }}
        closeAction={() => setItemDelete(null)}
      />
    </div>
  );
};

export default ProjectsList;
