import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

import App from "./App";

if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_OBSURVANT_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.3,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
