import Cookies from "js-cookie";

const KEYS = {
  token: "obsurvant-token",
};

export const userToken = {
  write: (token: string): string | undefined => {
    return Cookies.set(KEYS.token, token, {
      expires: 30,
      domain: import.meta.env.VITE_APP_OBSURVANT_COOKIE_DOMAIN || "localhost",
    });
  },
  read: (): string | null => Cookies.get()[KEYS.token],
  delete: (): void => {
    return Cookies.remove(KEYS.token, {
      domain: import.meta.env.VITE_APP_OBSURVANT_COOKIE_DOMAIN || "localhost",
    });
  },
};
