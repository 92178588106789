import { getRequest, postRequest, patchRequest, deleteRequest } from "../utils/Axios";

/**
 * Fetches all of the suppliers
 * @returns Promise<GenericApiResult>
 */
export async function fetchSuppliers(): Promise<GenericApiResult> {
  let loading = true;
  let error: string | null = null;
  let data: never[] = [];

  await getRequest("suppliers")
    .then((response) => {
      data = response.data;
      error = null;
      loading = false;
    })
    .catch((err) => {
      error = err.message;
      loading = false;
    });

  return {
    loading,
    error,
    data,
  };
}

/**
 * Fetches a single supplier by id
 * @param string|undefined id
 * @returns Promise<SupplierApiResult>
 */
export async function fetchSupplier(id: string | undefined): Promise<SupplierApiResult> {
  let loading = true;
  let error: string | null = null;
  let data: SupplierData | null = null;

  if (typeof id !== "string") {
    loading = false;
    error = "Supplier id missing from request";
    return {
      loading,
      error,
      data,
    };
  }

  await getRequest("suppliers/" + id)
    .then((response) => {
      data = response.data;
      error = null;
      loading = false;
    })
    .catch((err) => {
      error = err.message;
      loading = false;
    });

  return {
    loading,
    error,
    data,
  };
}

/**
 * Create a supplier
 * @param SupplierCreateVars input
 * @returns Promise<SupplierCreateData>
 */
export async function createSupplier(input: SupplierCreateVars): Promise<SupplierCreateData> {
  let result: undefined | Project = undefined,
    messages: string[] = [];

  await postRequest("suppliers", input)
    .then((response) => {
      const data = response.data.supplierCreate;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.supplierCreate;
      messages = data.messages;
    });

  return {
    supplierCreate: {
      result: result,
      messages: messages,
    },
  };
}

/**
 * Update a supplier
 * @param SupplierUpdateVars input
 * @returns Promise<SupplierUpdate>
 */
export async function updateSupplier(
  id: string | undefined,
  input: SupplierUpdateVars
): Promise<SupplierUpdate> {
  let result: undefined | Project = undefined,
    messages: string[] = [];

  if (typeof id !== "string") {
    return {
      supplierUpdate: {
        result: result,
        messages: [{ field: "base", message: "Id missing from request" }],
      },
    };
  }

  await patchRequest("suppliers/" + id, input)
    .then((response) => {
      const data = response.data.supplierUpdate;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.supplierUpdate;
      messages = data.messages;
    });

  return {
    supplierUpdate: {
      result: result,
      messages: messages,
    },
  };
}

/**
 * Delete a single supplier by id
 * @param string|undefined id
 * @returns Promise<SupplierDeleteData>
 */
export async function deleteSupplier(id: string | undefined): Promise<SupplierDeleteData> {
  let result: undefined | Supplier = undefined,
    messages: string[] = [];

  if (typeof id !== "string") {
    messages.push("An error occurred while deleting the Supplier");
    return {
      supplierDelete: {
        result: result,
        messages: messages,
      },
    };
  }

  await deleteRequest("suppliers/" + id)
    .then((response) => {
      const data = response.data.supplierDelete;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.supplierDelete;
      messages = data.messages;
    });

  return {
    supplierDelete: {
      result: result,
      messages: messages,
    },
  };
}
