import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { useFormikContext, Field, FieldArray } from "formik";
import { TextField, Select } from "formik-material-ui";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SupplierQuestions from "../Suppliers/Questions/SupplierQuestions";

import ModelFormSelect from "../../themes/components/ModelFormSelect";
import Tooltip from "../../themes/components/Tooltip";
import useSharedStyles from "../../styles/Shared";
import useButtonStyles from "../../styles/Buttons";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { notifySimple } from "../../themes/components/Notifications";

type Props = {
  suppliers: Supplier[];
};

const ProjectForm: React.FC<Props> = ({ suppliers }) => {
  const navigate = useNavigate();
  const { values, setFieldValue, handleSubmit } = useFormikContext<ProjectCreateVars>();
  const [newSupplierId, setNewSupplierId] = useState<Supplier["id"]>("");

  const { classes } = useSharedStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();

  console.log(values.input.suppliers);

  const handleFieldClick = (supplierIdx: number, supplier_field_id: string) => {
    const project_suppliers = values.input.suppliers || [];
    const existingField = project_suppliers[supplierIdx].fields.find(
      (f) => f.supplier_field_id === supplier_field_id
    );
    if (existingField) {
      if (existingField.id) {
        // already saved field, toggle deleted param
        const fieldIdx = project_suppliers[supplierIdx].fields.indexOf(existingField);
        if (fieldIdx !== -1) {
          setFieldValue(
            `input.suppliers.${supplierIdx}.fields.${fieldIdx}.deleted`,
            !existingField.deleted
          );
        }
      } else {
        setFieldValue(
          `input.suppliers.${supplierIdx}.fields`,
          project_suppliers[supplierIdx].fields.filter(
            (f) => f.supplier_field_id !== supplier_field_id
          )
        );
      }
    } else {
      setFieldValue(`input.suppliers.${supplierIdx}.fields`, [
        ...project_suppliers[supplierIdx].fields,
        { supplier_field_id: supplier_field_id },
      ]);
    }
  };

  const renderFormButtons = () => (
    <div>
      <Button
        onClick={() => navigate("/")}
        className={cx(buttonClasses.largeSecondary, buttonClasses.red)}
        style={{ marginRight: 8 }}
      >
        Cancel
      </Button>
      <Button
        onClick={() => handleSubmit()}
        className={cx(buttonClasses.largeSecondary, buttonClasses.blue)}
      >
        Save
      </Button>
    </div>
  );

  const updateSupplierQuestions = (inputName: string, questions: ProjectSupplierQuestion[]) => {
    setFieldValue(inputName, questions);
  };

  return (
    <div className={classes.contentBox}>
      <div className={classes.inputBox}>
        <Field
          component={TextField}
          name="input.name"
          label="Name"
          variant="standard"
          fullWidth
          autoFocus
        />
      </div>
      <div className={classes.inputBox}>
        <ModelFormSelect
          fullWidth
          variant="standard"
          label="Status"
          value={values.input.status}
          onChange={(e) => {
            setFieldValue(`input.status`, e.target.value);
          }}
        >
          {["PENDING", "RUNNING", "CLOSED"].map((status) => (
            <MenuItem key={status} value={status}>
              {status.toLowerCase()}
            </MenuItem>
          ))}
        </ModelFormSelect>
      </div>
      <FieldArray
        name="input.suppliers"
        render={(arrayHelpers) => (
          <>
            <Typography variant="h3">Suppliers</Typography>
            {values.input.suppliers?.map((project_supplier, sIdx) =>
              project_supplier.deleted ? (
                <div key={sIdx}></div>
              ) : (
                <Box key={sIdx} className={classes.inputBox} display="flex" alignItems="flex-start">
                  <Box width="100%">
                    <Typography variant="h4">
                      {suppliers.find((sup) => sup.id === project_supplier.supplier_id)?.name || ""}
                    </Typography>
                    <div className={classes.inputBox}>
                      <Field
                        component={TextField}
                        name={`input.suppliers.${sIdx}.description`}
                        label="Description"
                        variant="standard"
                        fullWidth
                        style={{ marginRight: 8 }}
                      />
                    </div>
                    <div className={classes.inputBox}>
                      <Field
                        component={TextField}
                        name={`input.suppliers.${sIdx}.survey_url`}
                        label="Survey base URL"
                        variant="standard"
                        fullWidth
                        style={{ marginRight: 8 }}
                      />
                    </div>
                    <div className={classes.inputBox}>
                      <Field
                        component={TextField}
                        name={`input.suppliers.${sIdx}.limit`}
                        label="Responses limit"
                        variant="standard"
                        type="number"
                        fullWidth
                      />
                    </div>
                    <div>
                      <input
                        type="hidden"
                        name={`input.suppliers.${sIdx}.supplier_id`}
                        value={project_supplier.supplier_id}
                      />
                    </div>
                    Survey redirect URLs dynamic placeholders markup
                    <Typography variant="subtitle1">
                      These should be provided by the Survey Tool to wrap any dynamic URL variable,
                      for example <code>{"{{RID}}"}</code>
                    </Typography>
                    <Box display="flex">
                      <div className={classes.inputBox} style={{ marginRight: 16 }}>
                        <Field
                          component={TextField}
                          name={`input.suppliers.${sIdx}.opening_tag`}
                          label="Opening tag"
                          variant="standard"
                        />
                      </div>
                      <div className={classes.inputBox}>
                        <Field
                          component={TextField}
                          name={`input.suppliers.${sIdx}.closing_tag`}
                          label="Closing tag"
                          variant="standard"
                        />
                      </div>
                    </Box>
                    Survey URLs variables mapping
                    <Box display="flex">
                      <div className={classes.inputBox} style={{ marginRight: 16 }}>
                        <Field
                          component={TextField}
                          name={`input.suppliers.${sIdx}.respondent_key`}
                          label="obsrv_id"
                          variant="standard"
                        />
                      </div>
                      <div className={classes.inputBox}>
                        <Field
                          component={TextField}
                          name={`input.suppliers.${sIdx}.status_key`}
                          label="obsrv_v"
                          variant="standard"
                        />
                      </div>
                    </Box>
                    <div className={classes.inputBox}>
                      Fields:
                      {suppliers
                        .find((sup) => sup.id === project_supplier.supplier_id)
                        ?.fields.map((supplier_field) => (
                          <div key={supplier_field.id}>
                            <FormControlLabel
                              disabled={
                                supplier_field.required &&
                                project_supplier.fields
                                  .filter((f) => !f.deleted)
                                  .map((f) => f.supplier_field_id as string)
                                  .includes(supplier_field.id)
                              }
                              label={supplier_field.name || ""}
                              control={
                                <Checkbox
                                  color="secondary"
                                  checked={project_supplier.fields
                                    .filter((f) => !f.deleted)
                                    .map((f) => f.supplier_field_id as string)
                                    .includes(supplier_field.id)}
                                />
                              }
                              onChange={() => handleFieldClick(sIdx, supplier_field.id)}
                            />
                          </div>
                        ))}
                    </div>
                    {project_supplier.id && (
                      <Box width="100%">
                        <p>Pre Survey Questions</p>
                        <div className={classes.inputBox}>
                          <Field
                            component={TextField}
                            name={`input.suppliers.${sIdx}.pre_survey_id`}
                            label="Pre Survey Id"
                            variant="standard"
                            type="text"
                            fullWidth
                          />
                        </div>
                        <FormControl
                          variant="standard"
                          fullWidth
                          key={sIdx}
                          sx={{
                            marginTop: "0.5rem",
                            marginBottom: "2rem",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "0",
                          }}
                        >
                          <Field
                            component={Select}
                            name={`input.suppliers.${sIdx}.pre_survey_tool`}
                            label="Pre Survey Tool"
                            variant="standard"
                            fullWidth
                            sx={{ width: "100%" }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="roundtable">Roundtable AI</MenuItem>
                          </Field>
                        </FormControl>
                        {project_supplier.pre_survey_tool && (
                          <SupplierQuestions
                            questions={project_supplier.questions}
                            name={`input.suppliers.${sIdx}.questions`}
                            updateQuestions={updateSupplierQuestions}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                  <Tooltip title="Delete" placement="top">
                    <IconButton
                      onClick={() => {
                        if (!project_supplier.id) {
                          arrayHelpers.remove(sIdx);
                        } else {
                          setFieldValue(`input.suppliers.${sIdx}.deleted`, true);
                        }
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )
            )}
            <Box display="flex" alignItems="flex-start" pb={2}>
              <ModelFormSelect
                variant="standard"
                value={newSupplierId}
                onChange={(e) => setNewSupplierId(e.target.value as string)}
                style={{ width: isMobileOnly ? "50%" : 300, flexShrink: 1, marginRight: 10 }}
              >
                {suppliers.map((supplier) => (
                  <MenuItem key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </MenuItem>
                ))}
              </ModelFormSelect>
              <Button
                className={cx(buttonClasses.small, buttonClasses.iconButton, buttonClasses.blue)}
                onClick={() => {
                  if (newSupplierId) {
                    arrayHelpers.push({
                      supplier_id: newSupplierId,
                      limit: 0,
                      survey_url: "",
                      opening_tag: "{{",
                      closing_tag: "}}",
                      respondent_key: "",
                      status_key: "",
                      fields:
                        suppliers
                          .find((sup) => sup.id === newSupplierId)
                          ?.fields.filter((field) => field.required)
                          .map((field) => ({ supplier_field_id: field.id })) || [],
                    });
                  } else {
                    notifySimple(["Select an existing Supplier"], "error");
                  }
                }}
              >
                <ControlPointIcon /> Add a Supplier
              </Button>
            </Box>
          </>
        )}
      />

      <Box display="flex" justifyContent="flex-end">
        {renderFormButtons()}
      </Box>
    </div>
  );
};

export default ProjectForm;
