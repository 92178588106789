import { useEffect } from "react";
import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";

import Loader from "../../themes/components/Loader";
import { notify, notifySimple } from "../../themes/components/Notifications";
import { finalizeResponse } from "../../api/Project";

const KEY = "obsrvnow-response";

type ResponseStorage = {
  id: string;
  respondent_key: string;
  status_key: string;
};

const ResponseFinalizeRoute: React.FC = () => {
  const urlParams = queryString.parse(document.location.search);

  let idKey = "obsrv_id";
  const sessionVal = sessionStorage.getItem(KEY);
  if (sessionVal) {
    const storedValue = JSON.parse(sessionVal) as ResponseStorage;

    if (
      typeof urlParams[storedValue.respondent_key] === "string" &&
      urlParams[storedValue.respondent_key] === storedValue.id
    ) {
      idKey = storedValue.respondent_key;
    }
  }

  const responseId = typeof urlParams[idKey] === "string" ? (urlParams[idKey] as string) : "";

  const onFinalizeResponse = async () => {
    try {
      const response = await finalizeResponse({
        id: responseId,
        urlParams: JSON.stringify(omit(urlParams, [idKey])),
      });
      const { result, messages } = response.responseFinalize;

      if (!isEmpty(messages)) {
        notify(messages, "error");
      } else if (result) {
        window.location.href = result.redirect_url;
      }
    } catch (err) {
      notifySimple(["Unexpected error occurred"], "error");
    }
  };

  useEffect(() => {
    void onFinalizeResponse();
  }, []);

  return <Loader />;
};

export default ResponseFinalizeRoute;
