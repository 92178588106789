import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useSearchParams from "./useSearchParams";

type Pagination = {
  currentPage: number;
  offset: number;
  pagesCount: number;
  onPageChange: (page: number) => void;
};

export const usePagination = (
  itemsCount: number,
  limitPerPage: number,
  withoutURL?: boolean
): Pagination => {
  const { params, setParams } = useSearchParams();
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const pagesCount = Math.ceil(itemsCount / limitPerPage);

  const [currentPage, setCurrentPage] = useState(
    !withoutURL && (params.page || params.page === "") ? Number.parseInt(params.page as string) : 1
  );

  const onPageChange = (page: number): void => {
    setCurrentPage(page);
    setOffset((page - 1) * limitPerPage);

    if (!withoutURL) {
      navigate({ search: page === 1 ? "" : `?page=${page}` });
    }
  };

  useEffect(() => {
    if (!withoutURL) {
      if (params.page || params.page === "") {
        if (Number.isNaN(currentPage) || currentPage <= 0 || currentPage > pagesCount) {
          params.page = 1;
          setParams(params);
        } else {
          setOffset((currentPage - 1) * limitPerPage);
        }
      }
    }
  }, []);

  return {
    currentPage,
    offset,
    pagesCount,
    onPageChange,
  };
};
