import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Select, { SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

import UserAvatar from "../Profile/Avatar";
import useStyles from "./Styles";
import useButtonStyles from "../../styles/Buttons";
import usePopoverStyles from "../../styles/Popovers";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";

const RoleSelect = styled(Select)<SelectProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  borderRadius: 4,
  backgroundColor: "#EBEAED",
  "&::before, &::after, &:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
  "& .MuiInputBase-input": {
    paddingTop: theme.spacing(1),
    borderBottom: "none",
  },
}));

const UsersList: React.FC = () => {
  const { classes } = useStyles();
  const { classes: buttonClasses, cx } = useButtonStyles();
  const { classes: popoverClasses } = usePopoverStyles();

  const [showAddUser, setShowAddUser] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [newUserRole, setNewUserRole] = useState("viewer");

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h3">Users</Typography>
        <Button
          className={cx(buttonClasses.blue, buttonClasses.iconButton)}
          onClick={() => setShowAddUser((prev) => !prev)}
        >
          <ControlPointIcon /> Add User
        </Button>
      </Box>

      <Collapse in={showAddUser} mountOnEnter unmountOnExit>
        <Box display="flex" alignItems="center" pb={2}>
          <TextField label="User Email" fullWidth variant="standard" type="text" />
          <Box px={2}>
            <RoleSelect
              labelId="role-select-label"
              id="role-select"
              variant="filled"
              value={newUserRole}
              onChange={(e) => setNewUserRole(e.target.value as string)}
              label="Role"
            >
              <MenuItem value="viewer">Viewer</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </RoleSelect>
          </Box>
          <Button className={buttonClasses.blue}>Invite</Button>
          <IconButton onClick={() => setShowAddUser(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Collapse>

      <Table className={classes.usersTable}>
        <TableBody>
          {Array.from(Array(5).keys()).map((i) => (
            <TableRow key={i}>
              <TableCell>
                <div className={classes.userWrapper}>
                  <UserAvatar />
                  <div>
                    <div className={classes.itemName}>Maria Roselia</div>
                    <div className={classes.itemSubName}>vaz@vid.io</div>
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <div className={classes.userRole}>Product Marketing Manager</div>
              </TableCell>
              <TableCell align="right">Viewer</TableCell>
              <TableCell align="right" width={50}>
                <IconButton
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  className={classes.userSettingsButton}
                >
                  <SettingsIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        disableScrollLock
        PaperProps={{
          className: cx(popoverClasses.arrow, popoverClasses.arrowRight),
        }}
      >
        <List className={classes.userDropdownMenu}>
          <ListItem
            button
            onClick={() => setAnchorEl(null)}
            className={classes.userDropdownMenuItem}
          >
            Change role
          </ListItem>

          <ListItem
            button
            onClick={() => setAnchorEl(null)}
            className={classes.userDropdownMenuItem}
          >
            Remove
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default UsersList;
