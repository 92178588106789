import React, { useState } from "react";
import '../../styles/presurvey.css';
import Loader from "../../themes/components/Loader";

type Props = {
  questions: ProjectSupplierQuestion[];
  submitAnswers: (answers: PreSurveyAnswer[]) => void;
};

const PreSurvey: React.FC<Props> = ({ questions, submitAnswers }) => {
  const [activeQuestionNumber, setActiveQuestionNumber] = useState<number>(1),
    [answers, setAnswers] = useState<PreSurveyAnswer[]>([]),
    [answer, setAnswer] = useState<string>(""),
    [error, setError] = useState<string>(""),
    [processing, setProcessing] = useState<boolean>(false);

  const getCurrentQuestion = (): ProjectSupplierQuestion | null => {
    const question = questions.find(function (obj) {
      return obj.number === activeQuestionNumber;
    });

    if (!question) {
      return null;
    }

    return question;
  };

  const nextQuestion = (): void => {
    const currentQuestion = getCurrentQuestion();

    if (!currentQuestion) {
      return;
    }

    if (!answer || answer.length < 1) {
      setError("Please fill this in");
      return;
    }

    setError("");

    const answersCopy = [...answers];

    answersCopy.push({
      id: currentQuestion.id,
      answer: answer,
    });

    setAnswers(answersCopy);

    if (currentQuestion.number === questions.length) {
      setProcessing(true);
      submitAnswers(answersCopy);
      setAnswer("");
      setAnswers([]);
      setActiveQuestionNumber(1);
      return;
    }

    setAnswer("");
    setActiveQuestionNumber(activeQuestionNumber + 1);
  };

  const onClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    nextQuestion();
  };

  const shouldProceed = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    const keyCode = e.code;
    setError("");

    if (keyCode === "Enter") {
      e.preventDefault();
      nextQuestion();
    }
  };

  const getSubmitButtonText = (): string => {
    const currentQuestion = getCurrentQuestion();

    if (!currentQuestion) {
      return "Next";
    }

    if (currentQuestion.number === questions.length) {
      return "Submit";
    }

    return "Next";
  };

  if (processing) {
    return <Loader />;
  }

  const currentQuestion = getCurrentQuestion();

  if (currentQuestion === null) {
    return null;
  }

  return (
    <div className="presurvey">
      <div className="presurvey__question">
        <div className="presurvey__label">
          <p>
            {currentQuestion.number} <span>&#8594;</span>
          </p>
          <label htmlFor={currentQuestion.id}>{currentQuestion.title}*</label>
        </div>
        <div className="presurvey__actions">
          <textarea
            name={`question[${currentQuestion.id}]`}
            onChange={(e) => setAnswer(e.target.value)}
            value={answer}
            id={currentQuestion.id}
            placeholder="Type your answer here..."
            onKeyDown={(e) => shouldProceed(e)}
            rows="1"
            autoFocus
            autoComplete="off"
          />
          {error !== "" ? (
            <div className="error">
              <svg height="24" viewBox="0 0 24 24" width="24">
                <path
                  clipRule="evenodd"
                  d="M16.336 18H7.003c-1.51 0-2.475-1.609-1.765-2.941l4.667-8.75c.753-1.412 2.776-1.412 3.53 0l4.666 8.75c.71 1.332-.255 2.94-1.765 2.94zM11.67 8.5a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1zm0 7.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                  fillRule="evenodd"
                />
              </svg>
              <span>{error}</span>
            </div>
          ) : (
            <>
              <button onClick={onClick}>{getSubmitButtonText()}</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreSurvey;
