/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSearchParams } from "react-router-dom";

type CustomParams = {
  params: { [key: string]: any };
  setParams: (value: JsonObject) => void;
  paramsString: string;
};

const useCustomSearchParams = (): CustomParams => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject: any = Object.fromEntries(new URLSearchParams(search));

  return {
    params: searchAsObject,
    setParams: setSearch,
    paramsString: search.toString(),
  };
};

export default useCustomSearchParams;
