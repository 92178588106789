import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import MainLayout from "../../themes/layouts/Main";
import SuppliersList from "../../components/Suppliers/List";
import Loader from "../../themes/components/Loader";
import { fetchSuppliers } from "../../api/Supplier";

const SuppliersListRoute: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<never[]>([]);

  const fetchData = async () => {
    const result = await fetchSuppliers();
    setLoading(result.loading);
    setError(result.error);
    setData(result.data);
  };

  useEffect(() => {
    void fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error || !data) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>
    );
  }

  return (
    <MainLayout>
      <SuppliersList suppliers={data} refetch={fetchData} />
    </MainLayout>
  );
};

export default SuppliersListRoute;
