import isEmpty from "lodash/isEmpty";
import axios from "axios";
import { apiUrl } from "../../utils/Axios";

import ResendActivationForm from "../../components/Auth/ResendActivation";
import { notify, notifySimple } from "../../themes/components/Notifications";

const UserResendActivationRoute: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (values: ResendActivationVars, { setSubmitting, resetForm }: any) => {
    try {
      axios
        .post(apiUrl() + "resend-activation-email", values)
        .then((response) => {
          const userResendActivation = response.data.userResendActivation;
          if (isEmpty(userResendActivation.messages) && userResendActivation.result) {
            notifySimple(["Please check your email to continue"], "info");
            resetForm();
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            notify(userResendActivation.messages);
          }
        })
        .catch((err) => {
          const userResendActivation = err.response.data.userResendActivation;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          notify(userResendActivation.messages, "error");
        });
    } catch (error) {
      setSubmitting(false);
      notifySimple(["We couldn't find an account associated with this email"], "error");
    }
  };

  return <ResendActivationForm onSubmit={handleSubmit} />;
};

export default UserResendActivationRoute;
