import { getRequest, postRequest, patchRequest } from "../utils/Axios";
import { userToken } from "../utils/Cookies";

/**
 * Fetches a user profile
 * @returns Promise<GenericApiResult>
 */
export async function fetchUser(): Promise<GenericApiResult> {
  let loading = true;
  let error: Error | null = null;
  let data: UserAccount | null = null;

  await getRequest("me")
    .then((response) => {
      data = response.data.userCurrent;
    })
    .catch(() => {
      error = { name: "401", message: "User not authenticated" };
    });

  loading = false;

  return {
    data,
    loading,
    error,
  };
}

/**
 * Update a user
 * @param UserUpdateVars input
 * @returns Promise<UserUpdate>
 */
export async function updateSelf(input: UserUpdateVars): Promise<UserUpdate> {
  let result: undefined | UserUpdate = undefined,
    messages: string[] = [];

  await patchRequest("me", input)
    .then((response) => {
      const data = response.data.userUpdate;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.userUpdate;
      messages = data.messages;
    });

  return {
    userUpdate: {
      result: result,
      messages: messages,
    },
  };
}

/**
 * Update a user
 * @param UserPasswordChangeVars input
 * @returns Promise<UserPasswordChangeUpdate>
 */
export async function updatePassword(
  input: UserPasswordChangeVars
): Promise<UserPasswordChangeData> {
  let result: undefined | string = undefined,
    messages: string[] = [];

  await patchRequest("change-password", input)
    .then((response) => {
      const data = response.data.userPasswordChange;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.userPasswordChange;
      messages = data.messages;
    });

  return {
    userPasswordChange: {
      result: result,
      messages: messages,
    },
  };
}

/**
 * Logs a user out
 * @returns Promise<boolean>
 */
export async function logout(): Promise<boolean> {
  let logged_out = false;

  await postRequest("logout", { token: userToken.read() })
    .then((response) => {
      logged_out = response.data.logged_out;
      userToken.delete();
    })
    .catch(() => {
      logged_out = false;
    });

  return logged_out;
}

/**
 * Create a user
 * @param CreateUserVars input
 * @returns Promise<CreateUserData>
 */
export async function createUser(input: CreateUserVars): Promise<CreateUserData> {
  let result: undefined | CreateUserData = undefined,
    messages: string[] = [];

  await postRequest("sign-up", input)
    .then((response) => {
      const data = response.data.userCreate;
      result = data.result;
      messages = data.messages;
    })
    .catch((err) => {
      const data = err.response.data.userCreate;
      messages = data.messages;
    });

  return {
    userCreate: {
      result: result,
      messages: messages,
    },
  };
}
