import { makeStyles } from "../../styles";

export default makeStyles()((theme) => ({
  mainCol: {
    maxWidth: "calc(100% - 300px)",
  },
  inputBox: {
    paddingBottom: theme.spacing(2),
  },
  usersTable: {
    "& tr:last-child td": {
      borderBottom: "none",
    },
  },
  userWrapper: {
    display: "flex",
    alignItems: "center",
    "*:first-of-type": {
      marginRight: theme.spacing(1),
    },
  },
  itemName: {
    fontSize: 14,
    fontWeight: 600,
  },
  itemSubName: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  userRole: {
    fontSize: 14,
    fontWeight: 500,
  },
  userSettingsButton: {
    color: theme.palette.text.secondary,
  },
  userDropdownMenu: {
    padding: theme.spacing(0.5),
  },
  userDropdownMenuItem: {
    fontSize: 12,
    fontWeight: 500,

    "& svg": {
      width: 18,
      marginRight: theme.spacing(1.5),
      color: theme.palette.text.secondary,
    },
  },
}));
