import { makeStyles } from "../../styles";

export default makeStyles()((theme) => ({
  mainCol: {
    maxWidth: "calc(100% - 300px)",
  },
  inputBox: {
    paddingBottom: theme.spacing(2),
  },
}));
